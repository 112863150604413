import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Form,
  Dropdown,
} from "react-bootstrap";

import InputMask from "react-input-mask";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import CheckBox from "../../components/CheckBox";
import RadioBtn from "../../components/RadioBtn";
import Select from "react-select";
import VerifyPwWithUser from "../../components/Modals/VerifyPwWithUser";

import list_wounds from "../../functions/list_wounds";
import api from "../../services/api";
import moment from "moment";
import { Show_Alert } from "../../store/actions/alert";
import { CalculateAge, userHasPermission } from "../../functions/utils";

function ModalSensor({ ...props }) {
  const {
    handler_modal,
    show_modal,
    data,
    handler_reload,
    reload,
    link,
    patient_id,
    room,
    subroom,
    date_birth,
    age,
    patient_name,
    beacon_name,
    beacon_id,
    unit_id,
  } = props;
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);

  const [showPw, setShowPw] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [dateBirth, setDateBirth] = useState("");

  const [listBeacons, setListBeacons] = useState([]);
  const [chooserBeacon, setChooserBeacon] = useState({
    label: beacon_name != undefined ? beacon_name : "Selecionar sensor",
    value: beacon_id != undefined ? beacon_id : 0,
  });

  const [batteryLevel, setBatteryLevel] = useState(0);
  const [showBatteryAlert, setShowBatteryAlert] = useState(false);

  useEffect(() => {
    function LoadBeacons() {
      api
        .get(`/beacons`, {
          params: {
            pagination: false,
            unit_id: unit_id,
            status: 1,
            free: true,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var array = [];
            response.data.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
                battery: item.battery,
              });
            });
            setListBeacons(array);

            // Verificar se algum sensor tem nível de bateria igual ou menor que 20%
            setShowBatteryAlert(array.some((sensor) => sensor.battery <= 30));

            if (chooserBeacon.value !== 0) {
              const selectedSensor = array.find(
                (sensor) => sensor.value === chooserBeacon.value
              );
              if (selectedSensor) {
                setBatteryLevel(selectedSensor.battery);
              }
            }
          }
        })
        .catch((error) => {
          console.log("LoadBeacons error " + error);
        });
    }

    LoadBeacons();
  }, [unit_id]);

  useEffect(() => {
    // Atualiza o nível da bateria com base no beacon escolhido
    const updateBatteryLevel = () => {
      const selectedSensor = listBeacons.find(
        (sensor) => sensor.value === chooserBeacon.value
      );
      if (selectedSensor) {
        console.log("Nível da bateria:", selectedSensor.battery); // Adicione este log
        setBatteryLevel(selectedSensor.battery);
        setShowBatteryAlert(selectedSensor.battery <= 30);
      } else {
        setBatteryLevel(0);
        setShowBatteryAlert(false);
      }
    };

    updateBatteryLevel();
  }, [chooserBeacon, listBeacons]);

  function ToggleBeacon(userId) {
    setLoadingBtn("flex");
    api
      .put(`/patients/${patient_id}`, {
        name: patient_name,
        beacon_id: link ? chooserBeacon?.value : 0,
        user_id: userId,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Toggle sensor success");
          console.log(response.data);
          dispatch(
            Show_Alert({
              type: "success",
              msg: `${
                link
                  ? "Sensor vinculado. Fixe-o no paciente agora."
                  : "Sensor desvinculado."
              }`,
            })
          );
          handler_reload(!reload);
          handler_modal(false);
        }
      })
      .catch((error) => {
        console.log("Toggle sensor " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: `Não foi possível ${
              link ? "vincular" : "desvincular"
            } o sensor nesse paciente`,
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function VerifyFields() {
    var verify = true;
    if (chooserBeacon.value == 0) verify = false;
    return verify;
  }

  return (
    <>
      <Modal
        show={show_modal}
        onHide={() => handler_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showPw && (
          <VerifyPwWithUser
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_request={ToggleBeacon}
          />
        )}

        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">
                  {link ? "Vincular" : "Desvincular"} sensor
                </div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
          </div>

          <div className="modal-all p-0">
            <Col className="mt-4">
              <Form.Label className="field-label">Nome</Form.Label>
              <input
                disabled={true}
                className="default-input"
                type="text"
                placeholder="Digite o nome do paciente"
                value={data.name != undefined ? data.name : data.patient_name}
              />
            </Col>

            <div className="d-flex mt-4">
              <Col className="mb-3 mb-md-0" col={12} md={6}>
                <Form.Label className="field-label">Quarto</Form.Label>
                <Select
                  captureMenuScroll={false}
                  isDisabled={true}
                  isSearchable={false}
                  noOptionsMessage={() => "Indisponível"}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  isClearable={false}
                  value={{ label: room || subroom, value: 0 }}
                />
              </Col>

              <Col className="mb-3 mb-md-0" col={12} md={6}>
                <Form.Label className="field-label">Leito</Form.Label>
                <Select
                  captureMenuScroll={false}
                  isDisabled={true}
                  isSearchable={false}
                  noOptionsMessage={() => "Indisponível"}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  isClearable={false}
                  value={{
                    label: room == "" || room == null ? "-" : subroom,
                    value: 0,
                  }}
                />
              </Col>
            </div>

            <div className="d-flex mt-4">
              <Col xs={12} md={8}>
                <Form.Label className="field-label">
                  Data de nascimento
                </Form.Label>
                <div
                  style={{ backgroundColor: Colors.lightest_gray }}
                  className="d-flex align-items-center default-input"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={24}
                    icon="calendar"
                    className="mr-2"
                  />
                  <InputMask
                    disabled={true}
                    mask={"99/99/9999"}
                    maskChar={null}
                    style={{ border: "none", backgroundColor: "transparent" }}
                    type="text"
                    placeholder="-"
                    value={moment(date_birth).format("DD/MM/YYYY")}
                  />
                </div>
              </Col>

              <Col xs={12} md={4}>
                <Form.Label className="field-label">Idade</Form.Label>
                <input
                  disabled={true}
                  style={{
                    background: "#468DFF1A",
                    color: Colors.brand_blue,
                    border: "none",
                    fontWeight: "bold",
                  }}
                  className="default-input text-center"
                  type="text"
                  placeholder="-"
                  value={age > 0 ? age : "-"}
                />
              </Col>
            </div>

            <div className="d-flex mt-4">
              <Col className="mb-3 mb-md-0" xs={12}>
                <Form.Label className="field-label">Sensor</Form.Label>
                <Select
                  captureMenuScroll={false}
                  isDisabled={!link}
                  menuPlacement={"top"}
                  isSearchable={false}
                  noOptionsMessage={() => "Indisponível"}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listBeacons}
                  isClearable={false}
                  value={chooserBeacon}
                  onChange={(item) => setChooserBeacon(item)}
                />
              </Col>
            </div>

            {showBatteryAlert && (
              <div className="d-flex flex-column align-items-start mt-2">
                <div className="card-low-battery align-items-start mt-2">
                  <div className="icon-and-text">
                    <div className="circle-style">
                      <Icon color={Colors.brand_red} size={24} icon="warning" />
                    </div>
                    <p className="font-card-low-battery">
                      O sensor selecionado está com bateria fraca. Recomendamos
                      utilizar outro sensor ou fazer a troca da bateria.
                    </p>
                  </div>
                  <div className="separator-line"></div>
                  <p className="battery-level-text">
                    Nível de bateria atual: {batteryLevel}%
                  </p>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div className="secondary-btn" onClick={() => handler_modal(false)}>
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (userHasPermission(user)) {
                  ToggleBeacon();
                  return false;
                }
                setShowPw(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSensor;
