import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import { SRLWrapper } from "simple-react-lightbox";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import {
  userHasPermissionExcludingNurse,
  userWithoutPermission,
  userWithoutPermissionIncludingNurse,
} from "../../functions/utils";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import "./styles.css";
import api from "../../services/api";
import { Show_Alert } from "../../store/actions/alert";
import VerifyPwWithUser from "./VerifyPwWithUser";

var indexImg = 0;
var idImg = 0;
var totalWounds = 0;
var currentWound = 1;

function WoundEditConfirm(props) {
  const modalScroll = useRef(null);

  const {
    handler_show_wound,
    show_wound,
    handler_reload,
    reload,
    wound_edit_confirm,
    is_confirm,
    is_confirmed,
    is_evolution,
    wounds,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [listNotes, setListNotes] = useState([]);

  //Mask
  const [maskWidth, setMaskWidth] = useState("9,999");
  const [maskHeight, setMaskHeight] = useState("9,999");

  //Campos pra editar no modal wound add
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [wound, setWound] = useState({
    label: wound_edit_confirm.wound.name,
    value: wound_edit_confirm.wound.id,
  });
  const [idStage, setIdStage] = useState(0);
  const [noExposure, setNoExposure] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [depth, setDepth] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [note, setNote] = useState("");
  const [woundTypeNote, setWoundTypeNote] = useState("");
  const [images, setImages] = useState([]);
  const [newName, setNewName] = useState("");
  const [newSlider, setNewSlider] = useState(0);
  const [patientWoundId, setPatientWoundId] = useState(0);
  const [medicalDevices, setMedicalDevices] = useState(null);
  const [exposureType, setExposureType] = useState([]);
  const [btnsSelected, setBtnsSelected] = useState([]);
  const [exposureTypeSetado, setExposureTypeSetado] = useState(false);
  const [btnsSetado, setBtnsSetado] = useState(false);
  const [titleTypeDescription, setTitleTypeDescription] = useState([
    {
      title: "Bordas",
      subtitle: "Tipos de bordas",
    },
  ]);
  const [newTitlesTypeDescription, setNewTitlesTypeDescription] = useState([
    {
      name: "Cor",
      title: "Pele adjacente",
    },
    {
      name: "Quantidade de exsudato",
      title: "Exsudato",
    },
  ]);

  const [listUsers, setListUsers] = useState([]);
  const [listWounds, setListWounds] = useState([]);
  const [listStages, setListStages] = useState([]);

  const [showPw, setShowPw] = useState(false);

  const [listTypeDescriptions, setListTypeDescriptions] = useState([]);
  const [listExposureTypes, setListExposureTypes] = useState({});
  const [listDescItems, setListDescItems] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [viewPwd, setViewPwd] = useState(false);
  const [showModalDeleteImg, setShowModalDeleteImg] = useState(false);

  const [listWoundsPatient, setListWoundsPatient] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState("none");

  const [imageIsOpen, setImageIsOpen] = useState(false);

  const [percentagesTotalValue, setPercentagesTotalValue] = useState(null);

  const [
    showPercentageExceedLimitWarning,
    setShowPercentageExceedLimitWarning,
  ] = useState(false);

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  useEffect(() => {
    function SetarData() {
      var found = wound_edit_confirm;

      setPatientWoundId(found.id);
      setMedicalDevices(found.medical_devices);
      setWound({ label: found.wound.name, value: found.wound.id });
      setIdStage(found.stage_id);
      setType(found.type);

      if (found.width != null) {
        var stringWidth = String(parseFloat(found.width).toFixed(2));
        setMaskWidth(stringWidth.length == 5 ? "99,99" : "9,99");
        setWidth(stringWidth.replace(".", ","));
      }
      if (found.height != null) {
        var stringHeight = String(parseFloat(found.height).toFixed(2));
        setMaskHeight(stringHeight.length == 5 ? "99,99" : "9,99");
        setHeight(stringHeight.replace(".", ","));
      }
      if (found.depth != null) {
        var stringDepth = String(parseFloat(found.depth).toFixed(2));
        setDepth(stringDepth.replace(".", ","));
      }

      setImages(found.images);
      setDescriptions(found.descriptions);

      let storedPercentageValue = 0;

      found.descriptions.forEach((i) => {
        const storedValue = parseInt(i.value, 10);
        if (!isNaN(storedValue)) {
          storedPercentageValue += storedValue;
        }
      });

      setPercentagesTotalValue(storedPercentageValue);

      LoadTypeDescriptions(found.descriptions);
      setWoundTypeNote(found.woundTypeNote?.note);
      setListNotes(found.notes);
    }

    SetarData();

    if (is_confirm) {
      currentWound = 1;

      //Altera a ordem da lista de lesão
      //Passo o clicado pra primeiro
      var array = [wound_edit_confirm];
      wounds.map((item) => {
        if (
          item.wound_id != wound_edit_confirm.wound_id &&
          item.user.id != user.dados.id &&
          item.date_verify_nurse == null
        ) {
          array.push(item);
        }
      });
      totalWounds = array.length;
      setListWounds(array);
    }
  }, []);

  useEffect(() => {
    if (userHasPermissionExcludingNurse(user)) {
      setUserChooser({
        label: user.dados.name,
        value: user.dados.id,
      });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      let permissions = "1,2";
      api
        .get(`/users`, {
          params: {
            permission_id: permissions,
            unit_id: patientSelected.dados.unit_id,
            exclude_user_id: is_evolution
              ? undefined
              : wound_edit_confirm.user.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermissionIncludingNurse(user)) {
      LoadUsers();
    }
  }, []);

  useEffect(() => {
    function LoadStages() {
      api
        .get(`/stages`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadStages");
            console.log(response.data);

            setListStages(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadStages();
  }, []);

  useEffect(() => {
    console.log(exposureType);
    setNoExposure(exposureType.length > 0 ? false : true);
    SetarDescriptions(exposureType, listExposureTypes.id);
  }, [exposureType]);

  useEffect(() => {
    listTypeDescriptions.forEach((item) => {
      if (item.descriptions.length > 0) {
        if (item.type === "btn-multi" && item.name !== "Exposição de:") {
          item.descriptions
            .filter((i) => i.id !== 0)
            .forEach((descItem, index) => {
              var multiBtns = descriptions.find((i) => {
                return i.type_description_id === descItem.type_description_id;
              });
              console.log(btnsSelected, btnsSetado);
              multiBtns = Array.isArray(multiBtns.description_id)
                ? multiBtns.description_id
                : [multiBtns.description_id];
              setBtnsSelected([
                {
                  typeDescriptionId: descItem.type_description_id,
                  values: multiBtns.filter((i) => i !== null && i !== ""),
                },
              ]);
              setBtnsSetado(true);
              var btns = btnsSelected.find(
                (i) => i.typeDescriptionId === descItem.type_description_id
              );
              console.log(btns, 2222333);
            });
        }
      }
    });
  }, [listTypeDescriptions]);

  function VerifyField() {
    let verify = true;
    if (
      userChooser.value == 0 ||
      type == "" ||
      (hospitalPlan.dados.type !== 2 && idStage == 0)
    )
      verify = false;
    return verify;
  }

  function LoadTypeDescriptions(desc) {
    api
      .get(`/typedescriptions`)
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadDescriptions");
          console.log(response.data);

          var array = [];
          response.data.map((item) => {
            var obj = {};
            obj.description_id = "";
            obj.type_description_id = item.id;
            obj.description_name = "";
            obj.value = "";
            if (desc != null) {
              var found = desc.filter((i) => {
                return i.type_description_id == item.id;
              });
              if (found != undefined && found.length > 0) {
                for (const item of found) {
                  obj.description_id =
                    found.length > 1
                      ? found.map((a) => a.description_id)
                      : item.description_id;
                  if (item.value != "" && item.value != null) {
                    obj.value = parseInt(item.value);
                  } else {
                    obj.value = item.value == null ? "" : "";
                  }
                }
              }
            }
            array.push(obj);
          });
          var arraySelect = [];
          response.data.map((item) => {
            var array = [];
            item.descriptions.map((i, index) => {
              var obj = {};
              obj.label = i.name;
              obj.value = i.id;
              array.push(obj);
            });

            if (array.length > 0) {
              arraySelect.push(array);
            }
          });

          var listTypeDescriptions = response.data.map((item) => {
            if (item.descriptions.length > 0 && item.id !== 16) {
              item.descriptions.unshift({
                id: 0,
                name: "Selecionar",
                type_description_id: item.descriptions[0].type_description_id,
              });
            }
            return item;
          });
          var exposureTypes = listTypeDescriptions.find((item) => {
            if (item.descriptions.length > 0) {
              return item.name === "Exposição de:";
            }
          });
          exposureTypes.descriptions.shift();
          array.map((i) => {
            if (i.description_id == [""]) {
              i.description_id = null;
            }
          });
          setDescriptions(array);
          setListTypeDescriptions(listTypeDescriptions);
          setListExposureTypes(exposureTypes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function SetarSlider(value, id) {
    let percentageAtualValue = 0;
    setShowPercentageExceedLimitWarning(false);
    if (percentagesTotalValue !== null && percentagesTotalValue <= 100) {
      descriptions.forEach((i) => {
        const storedValue = parseInt(i.value, 10);
        if (!isNaN(storedValue) && i.type_description_id !== id) {
          percentageAtualValue += storedValue;
        } else if (i.type_description_id === id) {
          percentageAtualValue += parseInt(value);
        }
      });
      if (percentageAtualValue > 100) {
        setShowPercentageExceedLimitWarning(true);
        value = 100 - (percentageAtualValue - parseInt(value));
      }
    }

    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.value = value;
      }
      return item;
    });

    setDescriptions(newList);
  }

  function SetarDescription(value, id) {
    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.description_id = value;
        item.value = "";
        item.description_name = "";
      }
      return item;
    });

    setDescriptions(newList);
  }

  function SetarDescriptions(value, id) {
    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.description_id = value;
        item.value = "";
        item.description_name = "";
      }
      return item;
    });
    setDescriptions(newList);
  }

  function SetarBtns(value, id) {
    var btns = btnsSelected.find((item) => item.typeDescriptionId === id);
    if (btns) {
      var existentBtns = btnsSelected.map((item) => {
        if (item.typeDescriptionId == id) {
          if (item.values.indexOf(value) > -1) {
            item.values = item.values.filter((i) => i !== value);
          } else {
            item.values.push(value);
          }
        }
        return item;
      });
      setBtnsSelected(existentBtns);
    } else {
      var newBtns = {
        typeDescriptionId: id,
        values: [value],
      };
      setBtnsSelected((btnsSelected) => [...btnsSelected, newBtns]);
    }
  }

  function CalculateArea(width, height) {
    var multi = "";
    var newWidth = width;
    var newHeight = height;

    if (String(width).match(/,/) != null) newWidth = width.replace(",", ".");
    if (String(height).match(/,/) != null) newHeight = height.replace(",", ".");

    multi = (newWidth * newHeight).toFixed(2).replace(".", ",");
    return multi;
  }

  function Preview(files) {
    if (files == undefined || files == null) return false;

    const reader = new FileReader();
    reader.onload = (r) => {
      var obj = {
        file: files[0],
        path: r.target.result,
        fileName: files[0].name,
        created_at: new Date(),
      };

      setImages([...images, obj]);
    };
    reader.readAsDataURL(files[0]);
  }

  function renderModalDeleteImg() {
    return (
      <Modal
        show={showModalDeleteImg}
        onHide={() => {
          setShowModalDeleteImg(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-pw"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={6} md={2} lg={6}>
              <div className="modal-title">
                Deseja realmente excluir essa foto?
              </div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => {
                  setShowModalDeleteImg(false);
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => {
                setShowModalDeleteImg(false);
              }}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              className="mb-0 modal-footer-btn btn-confirm"
              style={{ backgroundColor: Colors.brand_pink }}
              onClick={() => DeleteImg()}
            >
              <p className="mb-0">Sim</p>
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  function DeleteImg() {
    if (indexImg != null) {
      //Img local
      var newList = images.filter((item, index) => {
        if (index != indexImg) {
          return item;
        }
      });
      setImages(newList);
      setShowModalDeleteImg(false);
      return false;
    }

    //Img server
    api
      .delete(`/images_wounds/${idImg}`)
      .then((response) => {
        if (response.status == 204) {
          var newList = images.filter((item) => item.id != idImg);
          setImages(newList);
        }
      })
      .catch((error) => {
        console.log("DeleteImg error " + error);

        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível deletar essa imagem.",
          })
        );
      })
      .finally(() => setShowModalDeleteImg(false));
  }

  function ConfirmWound(userId) {
    setLoadingBtn("flex");

    //Descriptions
    var arrayDescriptions = [];
    descriptions.map((item) => {
      var obj = {};
      obj.type_description_id =
        item.new == undefined ? item.type_description_id : null;
      obj.description_id = item.description_id;
      obj.value = item.value;
      obj.id = item.id;

      arrayDescriptions.push(obj);
    });

    /* Add nova seção quando clica no btn "OUTROS" */
    if (newName != "" && newSlider > 0) {
      arrayDescriptions.push({
        type_description_name: newName,
        value: newSlider,
      });
    }

    api
      .put(
        `/patients/${wound_edit_confirm.patient_id}/wounds/${patientWoundId}`,
        {
          medical_devices: medicalDevices,
          type,
          stage_id: idStage,
          note,
          wound_type_note: woundTypeNote,
          user_id: userId ? userId : userChooser.value,
          verify_nurse_id: userId ? userId : userChooser.value,
          height: height != "" ? height.replace(",", ".") : "",
          width: width != "" ? width.replace(",", ".") : "",
          depth: depth != "" ? depth.replace(",", ".") : "",
          descriptions: arrayDescriptions,
        }
      )
      .then((response) => {
        if (response.status == 200) {
          SaveImages(false);
        }
      })
      .catch((error) => {
        console.log("ConfirmWound error " + error);

        Show_Alert({
          type: "error",
          msg: "Não foi possível confirmar essa lesão.",
        });
        setLoadingBtn("none");
      });
  }

  function EditWound(userId) {
    setLoadingBtn("flex");

    //Descriptions
    var arrayDescriptions = [];
    descriptions.map((item) => {
      var obj = {};
      obj.type_description_id =
        item.new == undefined ? item.type_description_id : null;
      obj.description_id = item.description_id;
      obj.value = item.value;
      obj.id = item.id;

      arrayDescriptions.push(obj);
    });

    /* Add nova seção quando clica no btn "OUTROS" */
    if (newName != "" && newSlider > 0) {
      arrayDescriptions.push({
        type_description_name: newName,
        value: newSlider,
      });
    }

    // console.log("idPatient " + patientSelected.dados.id);
    // console.log("idWoundPatient " + patientWoundId);

    api
      .put(
        `/patients/${wound_edit_confirm.patient_id}/wounds/${patientWoundId}`,
        {
          medical_devices: medicalDevices,
          type,
          stage_id: idStage,
          note,
          wound_type_note: woundTypeNote,
          user_id: userId ? userId : userChooser.value,
          height: height != "" ? height.replace(",", ".") : "",
          width: width != "" ? width.replace(",", ".") : "",
          depth: depth != "" ? depth.replace(",", ".") : "",
          descriptions: arrayDescriptions,
        }
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("EditWound success");
          console.log(response.data);
          SaveImages(true);
        }
      })
      .catch((error) => {
        console.log("EditWound error " + error);

        Show_Alert({
          type: "error",
          msg: "Não foi possível Editar essa lesão.",
        });
        setLoadingBtn("none");
      });
  }

  function SaveImages(edit) {
    //Verifica se tem imagem nova pra enviar
    var newImage = images.find((item) => item.patient_wound_id == undefined);
    if (newImage == undefined) {
      setLoadingBtn("none");
      AttWound();
      return false;
    }

    var formData = new FormData();
    images.map((item) => {
      if (item.patient_wound_id == undefined) {
        formData.append("image[]", item.file);
        formData.append("filename", item.fileName);
      }
    });

    console.log("patientWoundId " + patientWoundId);

    api
      .post(
        `/patients/${wound_edit_confirm.patient_id}/wounds/${patientWoundId}/images`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData.boundary}`,
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          AttWound();
        }
      })
      .catch((error) => {
        console.log("SaveImages error " + error);
        Show_Alert({
          type: "error",
          msg: "Não foi possível salvar as novas imagens da lesão",
        });
      })
      .finally(() => setLoadingBtn("none"));
  }

  function ResetFields() {
    setIdStage(0);
    setType("");
    setWidth("");
    setHeight("");
    setDepth("");
    setWoundTypeNote("");
    // setDescriptions([]);
    setNote("");
    setImages([]);
    setNewName("");
    setNewSlider("");
    setMedicalDevices(null);
  }

  function AttWound() {
    //Scroll to top modal
    modalScroll.current.scrollTop = 0;

    ResetFields();

    //Editando
    if (!is_confirm) {
      dispatch(
        Show_Alert({
          type: "success",
          msg: "Lesão editada",
        })
      );
      handler_reload(reload == true ? false : true);
      handler_show_wound(false);
      return false;
    }

    //Confirmando
    if (currentWound < listWounds.length) {
      dispatch(
        Show_Alert({
          type: "success",
          msg: `Lesão ${currentWound} - ${totalWounds} foi confirmada`,
        })
      );

      var found = listWounds[currentWound];
      currentWound++;
      setBtnsSetado(false);
      setBtnsSelected([]);
      setPatientWoundId(found.id);
      setWound({ label: found.wound.name, value: found.wound.id });
      setIdStage(found.stage_id);
      setType(found.type);
      setMedicalDevices(found.medical_devices);

      if (found.width != null) {
        var stringWidth = String(found.width.toFixed(2));
        setMaskWidth(stringWidth.length == 5 ? "99,99" : "9,99");
        setWidth(stringWidth.replace(".", ","));
      }
      if (found.height != null) {
        var stringHeight = String(found.height.toFixed(2));
        setMaskHeight(stringHeight.length == 5 ? "99,99" : "9,99");
        setHeight(stringHeight.replace(".", ","));
      }
      if (found.depth != null) {
        var stringDepth = String(found.depth.toFixed(2));
        setDepth(stringDepth.replace(".", ","));
      }

      setImages(found.images);
      setDescriptions(found.descriptions);
      LoadTypeDescriptions(found.descriptions);
      setListNotes(found.notes);
      setWoundTypeNote(found.woundTypeNote?.note);
      setNote("");
    } else {
      dispatch(
        Show_Alert({
          type: "success",
          msg: "Todas as lesões foram confirmadas com sucesso",
        })
      );
      handler_show_wound(false);
    }

    handler_reload(reload == true ? false : true);
  }

  function VerifyField() {
    let verify = true;
    if (
      type == "" ||
      (hospitalPlan.dados.type !== 2 && idStage == 0) ||
      medicalDevices == null
    )
      verify = false;
    return verify;
  }

  return (
    <Modal
      show={show_wound}
      onHide={() => {
        handler_show_wound(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-pw"
      backdrop="static"
      keyboard={!imageIsOpen}
    >
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar confirmação"}
          handler_request={is_confirm ? ConfirmWound : EditWound}
        />
      )}

      {showModalDeleteImg && renderModalDeleteImg()}

      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            {!is_confirm && !is_evolution && (
              <div className="modal-title">Editar lesão</div>
            )}
            {is_confirm && (
              <div className="modal-title">
                Confirmar lesão {`${currentWound} - ${totalWounds}`}
              </div>
            )}
            {is_evolution && <div className="modal-title">Evoluir lesão</div>}
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_show_wound(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all" ref={modalScroll}>
          <div className="mb-3">
            <Form.Label className="field-label label-bold">
              Local <span className="obrigatory-asterisk">*</span>
            </Form.Label>
            <Select
              captureMenuScroll={false}
              noOptionsMessage={() => "Sem opções"}
              isDisabled={true}
              classNamePrefix="react-select"
              placeholder={"Selecionar"}
              options={listWounds}
              isClearable={false}
              value={wound}
              onChange={(item) => setWound(item)}
            />
          </div>

          {!is_evolution && (
            <>
              <div className="mb-2">
                <Form.Label className="field-label w-100 label-bold">
                  Verificação <span className="obrigatory-asterisk">*</span>
                </Form.Label>
                <button
                  disabled={is_confirmed}
                  style={{
                    background:
                      type == "admission"
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setType("admission")}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: type == "admission" ? "white" : Colors.darker_gray,
                    }}
                  >
                    Admissão
                  </p>
                </button>

                <button
                  disabled={is_confirmed}
                  style={{
                    background:
                      type == "incidence"
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setType("incidence")}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: type == "incidence" ? "white" : Colors.darker_gray,
                    }}
                  >
                    Incidência
                  </p>
                </button>
              </div>

              {hospitalPlan.dados.type == 2 && (
                <div className="mb-4">
                  <Form.Label className="field-label label-bold">
                    Tipo de lesão
                  </Form.Label>
                  <Form.Control
                    placeholder="Digite o tipo de lesão"
                    as="textarea"
                    rows={1}
                    className="default-input"
                    style={{ borderRadius: 8 }}
                    type="text"
                    value={woundTypeNote}
                    onChange={(e) => setWoundTypeNote(e.target.value)}
                  />
                </div>
              )}

              <div className="mb-3">
                <Form.Label className="field-label w-100 label-bold">
                  Estágio <span className="obrigatory-asterisk">*</span>
                </Form.Label>
                {listStages.map((item, index) => {
                  return (
                    <button
                      disabled={is_confirmed}
                      key={index}
                      style={{
                        background:
                          item.id == idStage
                            ? Colors.brand_blue
                            : Colors.lightest_gray,
                      }}
                      className="btn-click"
                      onClick={() => setIdStage(item.id)}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color:
                            item.id == idStage ? "white" : Colors.darker_gray,
                        }}
                      >
                        {item.name}
                      </p>
                    </button>
                  );
                })}
              </div>

              <div className="mb-3">
                <Form.Label className="field-label w-100 label-bold">
                  Associada a dispositivo médico{" "}
                  <span className="obrigatory-asterisk">*</span>
                </Form.Label>
                <button
                  disabled={is_confirmed}
                  style={{
                    background:
                      medicalDevices == 1
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                    width: 100,
                  }}
                  className="btn-click"
                  onClick={() => setMedicalDevices(1)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: medicalDevices == 1 ? "white" : Colors.darker_gray,
                    }}
                  >
                    Sim
                  </p>
                </button>

                <button
                  disabled={is_confirmed}
                  style={{
                    background:
                      medicalDevices == 0
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                    width: 100,
                  }}
                  className="btn-click"
                  onClick={() => setMedicalDevices(0)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: medicalDevices == 0 ? "white" : Colors.darker_gray,
                    }}
                  >
                    Não
                  </p>
                </button>
              </div>
            </>
          )}
          {hospitalPlan.dados.type == 2 && is_evolution && (
            <div className="mb-3">
              <Form.Label className="field-label label-bold">
                Tipo de lesão
              </Form.Label>
              <Form.Control
                placeholder="Digite o tipo de lesão"
                as="textarea"
                rows={1}
                className="default-input"
                style={{ borderRadius: 8 }}
                type="text"
                value={woundTypeNote}
                onChange={(e) => setWoundTypeNote(e.target.value)}
              />
            </div>
          )}
          <div className="d-flex flex-wrap">
            <p className="w-100 label-bold">Tamanho</p>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="field-label">Largura (cm)</Form.Label>
                <InputMask
                  mask={maskWidth}
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="0,00"
                  value={width}
                  onChange={(e) => {
                    var value = e.target.value;
                    var mask = "9,999";

                    if (value.length == 4) {
                      var parts = value.split("");

                      if (parts[2] == ",") {
                        value = `${parts[0]},${parts[1]}${parts[3]}`;
                      }
                    }

                    if (value.length > 4) {
                      mask = "99,99";
                      var parts = value.split("");

                      if (parts[1] == ",") {
                        value = `${parts[0]}${parts[2]},${parts[3]}${parts[4]}`;
                      }
                    }

                    setMaskWidth(mask);
                    setWidth(value);
                  }}
                />
              </Col>

              <Col xs={12} md={4} className="my-3 my-md-0">
                <Form.Label className="field-label">Compr. (cm)</Form.Label>
                <InputMask
                  mask={maskHeight}
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="0,00"
                  value={height}
                  onChange={(e) => {
                    var value = e.target.value;
                    var mask = "9,999";

                    if (value.length == 4) {
                      var parts = value.split("");

                      if (parts[2] == ",") {
                        value = `${parts[0]},${parts[1]}${parts[3]}`;
                      }
                    }

                    if (value.length > 4) {
                      mask = "99,99";
                      var parts = value.split("");

                      if (parts[1] == ",") {
                        value = `${parts[0]}${parts[2]},${parts[3]}${parts[4]}`;
                      }
                    }

                    setMaskHeight(mask);
                    setHeight(value);
                  }}
                />
              </Col>

              <Col xs={12} md={4}>
                <Form.Label className="field-label">Área (cm²)</Form.Label>
                <input
                  style={{
                    backgroundColor: "rgba(70,141,255,.1)",
                    borderWidth: 0,
                    color: Colors.brand_blue,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  disabled={true}
                  className="default-input"
                  type="text"
                  placeholder="0.00"
                  value={CalculateArea(width, height)}
                />
              </Col>
            </Row>
          </div>

          <div className="mb-4 mt-4">
            <Form.Label className="field-label">Profundidade (cm)</Form.Label>
            <InputMask
              mask={"9,99"}
              maskChar={null}
              className="default-input"
              type="text"
              placeholder="0,00"
              value={depth}
              onChange={(e) => {
                setDepth(e.target.value);
              }}
            />
          </div>

          <div className="mb-4">
            {idStage === 5 && (
              <div>
                <Form.Label className="field-label">
                  {listExposureTypes.name}
                </Form.Label>
                <div>
                  {listExposureTypes.descriptions &&
                    listExposureTypes.descriptions.map((item, index) => {
                      var exposureBtns = descriptions.find((i) => {
                        return (
                          i.type_description_id == item.type_description_id
                        );
                      });
                      if (exposureType.length == 0 && !exposureTypeSetado) {
                        let exposures = Array.isArray(
                          exposureBtns.description_id
                        )
                          ? exposureBtns.description_id
                          : [exposureBtns.description_id];
                        setExposureType(
                          exposures
                            .filter((i) => {
                              return i !== null;
                            })
                            .filter((i) => i !== "")
                        );
                        setExposureTypeSetado(true);
                      }
                      if (item.name !== "Sem Exposição") {
                        return (
                          <button
                            key={index}
                            style={{
                              background: exposureType.includes(item.id)
                                ? Colors.brand_blue
                                : Colors.lightest_gray,
                            }}
                            className="btn-click"
                            onClick={() => {
                              let index = exposureType.indexOf(item.id);
                              if (index > -1) {
                                setExposureType((exposureType) =>
                                  exposureType.filter((i) => i !== item.id)
                                );
                              } else {
                                setExposureType((exposureType) => [
                                  ...exposureType,
                                  item.id,
                                ]);
                              }
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color: exposureType.includes(item.id)
                                  ? "white"
                                  : Colors.darker_gray,
                              }}
                            >
                              {item.name}
                            </p>
                          </button>
                        );
                      } else {
                        return (
                          <div className="d-flex">
                            <label className="simple-checkbox">
                              <input
                                type="checkbox"
                                checked={exposureType.length > 0 ? false : true}
                                onChange={() => {
                                  setNoExposure(true);
                                  setExposureType([]);
                                }}
                              ></input>
                              <span></span>
                            </label>
                            <labeL
                              className="ml-2"
                              style={{
                                fontSize: "11px",
                                fontWeight: 500,
                                color: "rgb(100 87 87)",
                                transform: "translateY(2.5px)",
                              }}
                            >
                              Sem Exposição
                            </labeL>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            )}
            <div className="label-w-description mb-2 mt-2">
              <p className="label-bold">Descrição</p>
              <span>Tipos de Tecido</span>
              {showPercentageExceedLimitWarning && (
                <div className="container-error mt-2 mb-3">
                  <p>
                    Você pode ajustar as porcentagens de cada tecido, mas a soma
                    precisa ser igual a 100%.
                  </p>
                </div>
              )}
            </div>

            {listTypeDescriptions.map((item, index) => {
              var found = descriptions.find(
                (a) => a.type_description_id == item.id
              );
              var found2 = item.descriptions.find(
                (i) => i.id == found?.description_id
              );
              var found3 = [];
              if (
                (Array.isArray(found?.description_id) &&
                  found.description_id[0] !== null &&
                  found.description_id[0] !== "") ||
                (item.type === "check-multi" &&
                  found.description_id !== null &&
                  found.description_id[0] !== "")
              ) {
                if (!Array.isArray(found.description_id)) {
                  found.description_id = [found.description_id];
                }
                found.description_id.map((i) => {
                  let itemDescription = item.descriptions.find(
                    (j) => j.id == i
                  );
                  found3.push({
                    label: itemDescription.name,
                    value: itemDescription.id,
                  });
                });
              }

              if (item.descriptions.length > 0) {
                var arrayList = [];
                item.descriptions.map((i) => {
                  arrayList.push({ label: i.name, value: i.id });
                });

                var descSelected = { label: "Selecionar", value: 0 };
                if (found2 != undefined) {
                  descSelected = { label: found2.name, value: found2.id };
                }
                let title = item.name;
                let subTitle = titleTypeDescription.find(
                  (i) => i.title == title
                )?.subtitle;
                let newTitle = newTitlesTypeDescription.find(
                  (i) => i.name == title
                )?.title;
                const divIsEmpty =
                  (item.type === "check" && item.name !== "Exposição de:") ||
                  (item.type === "btn-multi" &&
                    item.name !== "Exposição de:") ||
                  item.type === "check-multi";
                return (
                  divIsEmpty && (
                    <div key={index} className="mb-3">
                      {item.type === "btn-multi" &&
                        item.name !== "Exposição de:" && (
                          <div>
                            <div className="label-w-description mt-1">
                              <p className="label-bold">{title}</p>
                              <span className="mb-3 d-block">
                                {subTitle !== null && subTitle}
                              </span>
                            </div>
                            <div>
                              {item.descriptions
                                .filter((i) => i.id !== 0)
                                .map((item, index) => {
                                  var multiBtns = descriptions.find((i) => {
                                    return (
                                      i.type_description_id ==
                                      item.type_description_id
                                    );
                                  });
                                  if (btnsSelected.length == 0 && !btnsSetado) {
                                    let btns = Array.isArray(
                                      multiBtns.description_id
                                    )
                                      ? multiBtns.description_id
                                      : [multiBtns.description_id];
                                    setBtnsSelected([
                                      {
                                        typeDescriptionId:
                                          item.type_description_id,
                                        values: btns
                                          .filter((i) => {
                                            return i !== null;
                                          })
                                          .filter((i) => i !== ""),
                                      },
                                    ]);
                                    setBtnsSetado(true);
                                  }
                                  var btns = btnsSelected.find(
                                    (i) =>
                                      i.typeDescriptionId ==
                                      item.type_description_id
                                  );
                                  return (
                                    <button
                                      key={index}
                                      style={{
                                        background: (
                                          btns
                                            ? btns.values.includes(item.id)
                                            : false
                                        )
                                          ? Colors.brand_blue
                                          : Colors.lightest_gray,
                                      }}
                                      className="btn-click"
                                      onClick={() => {
                                        SetarBtns(
                                          item.id,
                                          item.type_description_id
                                        );
                                        if (btns) {
                                          SetarDescriptions(
                                            btns.values,
                                            item.type_description_id
                                          );
                                        }
                                      }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{
                                          color: (
                                            btns
                                              ? btns.values.includes(item.id)
                                              : false
                                          )
                                            ? "white"
                                            : Colors.darker_gray,
                                        }}
                                      >
                                        {item.name}
                                      </p>
                                    </button>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      {item.type === "check-multi" && (
                        <div>
                          <p className="w-100 label-bold">
                            {newTitle !== null && newTitle}
                          </p>
                          <Form.Label className="field-label">
                            {item.name}
                          </Form.Label>
                          <Select
                            captureMenuScroll={false}
                            noOptionsMessage={() => "Sem opções"}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={arrayList.filter((i) => i.value !== 0)}
                            isClearable={false}
                            isMulti
                            styles={colourStyles}
                            closeMenuOnSelect={false}
                            value={found3}
                            onChange={(i) => {
                              var types = [];
                              for (let description of i !== null ? i : []) {
                                types.push(description.value);
                              }
                              SetarDescriptions(types, item.id);
                            }}
                          />
                        </div>
                      )}
                      {item.type === "check" && item.name !== "Exposição de:" && (
                        <div>
                          <div className="mt-3 mb-1">
                            <p className="w-100 label-bold">
                              {newTitle !== null && newTitle}
                            </p>
                            <Form.Label className="field-label">
                              {item.name}
                            </Form.Label>
                          </div>
                          <Select
                            captureMenuScroll={false}
                            noOptionsMessage={() => "Sem opções"}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={arrayList}
                            isClearable={false}
                            value={descSelected}
                            onChange={(i) => {
                              var type = item.descriptions.find(
                                (a) => a.id == i.value
                              );
                              SetarDescription(
                                i.value,
                                type.type_description_id
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                );
              } else {
                return (
                  <div key={index} className="mb-2">
                    <p>{item.name}</p>
                    <div className="d-flex">
                      <Form.Control
                        type="range"
                        value={found.value == "" ? 0 : found.value}
                        onChange={(e) => SetarSlider(e.target.value, item.id)}
                      />
                      <div className="d-flex">
                        <input
                          className="text-right input-description-slider"
                          value={found.value == "" ? 0 : found.value}
                          onChange={(e) => {
                            var value = e.target.value;
                            if (value > 100) value = 100;
                            if (value < 0 || value == "") value = 0;

                            SetarSlider(value, item.id);
                          }}
                        />
                        <p>%</p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}

            <button
              onClick={() => setShowOther(showOther == true ? false : true)}
              style={{
                width: 150,
                height: 36,
                background: showOther == true ? "#FFE8F3" : "white",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: Colors.brand_pink,
                color: Colors.brand_pink,
                borderStyle: "solid",
              }}
            >
              <p>Adicionar outro</p>
            </button>

            {showOther && (
              <div className="mt-3">
                <input
                  className="default-input"
                  type="text"
                  placeholder="Digitar outro"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <div className="mt-2">
                  <div className="d-flex">
                    <Form.Control
                      type="range"
                      value={newSlider}
                      onChange={(e) => setNewSlider(e.target.value)}
                    />
                    <div className="d-flex">
                      <input
                        className="text-right input-description-slider"
                        value={newSlider}
                        onChange={(e) => {
                          var value = e.target.value;
                          if (value > 100) value = 100;
                          if (value < 0 || value == "") value = 0;

                          setNewSlider(value);
                        }}
                      />
                      <p>%</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4">
              <Form.Label className="field-label label-bold">
                {hospitalPlan.dados.type == 2
                  ? "Observações e condutas"
                  : "Notas"}
              </Form.Label>
              <Form.Control
                placeholder={
                  "Digite uma nova " +
                  (hospitalPlan.dados.type == 2
                    ? "observação e conduta"
                    : "nota")
                }
                as="textarea"
                rows={3}
                mask={"99.9"}
                maskChar={null}
                className="default-input"
                style={{ borderRadius: 8 }}
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>

            <div className="mt-4" style={{ maxHeight: 400, overflow: "auto" }}>
              {listNotes.map((item, index) => {
                return (
                  <div key={index} className="main-card py-1 mb-3">
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "100%",
                          }}
                          alt={item.name}
                          title={item.name}
                          src={
                            item.user.image != null
                              ? item.user.url_image
                              : require("../../assets/img/icon_user.png")
                          }
                        />

                        <div className="dados-default">
                          <div className="name-default">{item.user.name}</div>
                          <div className="date-default">
                            {moment(item.created_at).format("LLL")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <p className="mt-4 obs-text">{item.note}</p>
                  </div>
                );
              })}
            </div>

            <div className="mt-4">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <Form.Label className="field-label mb-0 label-bold">
                  Fotos
                </Form.Label>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setViewPwd(viewPwd == true ? false : true)}
                >
                  <Icon
                    color={Colors.darkest_gray}
                    size={20}
                    icon={viewPwd ? "eye-off" : "eye-on"}
                  />
                </div>
              </div>

              <SRLWrapper
                options={{
                  buttons: {
                    showDownloadButton: false,
                  },
                }}
                callbacks={{
                  onLightboxOpened: (object) => setImageIsOpen(true),
                  onLightboxClosed: (object) => setImageIsOpen(false),
                }}
              >
                <ul className="images p-0 d-flex overflow-auto">
                  {images.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="d-flex flex-column position-relative"
                        style={{ minWidth: 120, minHeight: 140 }}
                      >
                        <div key={index} className="photo-item mr-2">
                          <div
                            className="btn-remove-photo"
                            onClick={() => {
                              idImg = 0;
                              indexImg = null;
                              if (item.patient_wound_id == undefined) {
                                //Imagem local
                                indexImg = index;
                              } else {
                                //Imagem server
                                idImg = item.id;
                              }

                              setShowModalDeleteImg(true);
                            }}
                          >
                            <Icon color={"white"} size={15} icon={"x"} />
                          </div>

                          <img
                            alt={moment(item.created_at).format("LLL")}
                            src={
                              item.patient_wound_id == undefined
                                ? item.path
                                : item.url
                            }
                            style={{
                              filter: `blur(${viewPwd == true ? "0" : "3px"})`,
                            }}
                          />
                        </div>
                        <p className="photo-date">
                          {moment(item.created_at).format("LLL")}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </SRLWrapper>

              <button
                onClick={() => {
                  document.getElementById("fileUpload").click();
                }}
                className="mt-3"
                style={{
                  width: "100%",
                  background: "white",
                  borderRadius: 10,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: Colors.brand_pink,
                  color: Colors.brand_pink,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={Colors.brand_pink}
                  size={20}
                  icon={"image"}
                  className="mr-2"
                />
                <p>Adicionar fotos</p>
              </button>
              <input
                onChange={(e) => Preview(e.target.files)}
                className="d-none"
                type="file"
                id="fileUpload"
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            style={{ borderStyle: "solid" }}
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_show_wound(false);
            }}
          >
            Cancelar
          </button>
        </Col>

        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={
              (VerifyField() == true ? false : true) || loadingBtn === "flex"
            }
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                VerifyField() == true ? Colors.brand_pink : Colors.lighter_gray,
            }}
            onClick={() => {
              if (!userWithoutPermission(user)) {
                if (is_confirm) {
                  ConfirmWound();
                } else {
                  EditWound();
                }
              } else {
                setShowPw(true);
              }
            }}
          >
            <p
              className="mb-0"
              style={{
                color: VerifyField() == true ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              {is_confirm == true ? "Confirmar" : "Salvar"}
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default WoundEditConfirm;
