import moment from "moment";
import Colors from "../styles/Colors";
import _ from "lodash";
import { useCallback } from "react";

export function GenerateId() {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export function RemoveAspas(val) {
  if (val != null) return val.replace(/[\\"]/g, "");
}

export function ValidateEmail(email) {
  email = email.trim();
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isAuthenticated() {
  var token = localStorage.getItem("preveni_token_v2");
  if (token) return true;
  else return false;
}

// export function hasHospital() {
//   var root = localStorage.getItem("persist:root");
//   root = JSON.parse(root);
//   var user = JSON.parse(root.user);

//   if (user.dados.hospital_id != null) {
//     return true;
//   } else {
//     return false;
//   }
// }

export function GetImc(weight, height) {
  let imc = 0;
  if (weight != "" && height != "") {
    weight = weight.replace(",", ".");
    height = height.replace(",", ".");
    imc = weight / (height * height);
  }
  return imc.toFixed(2);
}

export function CalculateAge(birthDate) {
  if (
    birthDate == "Data inválida" ||
    birthDate == "Invalid date" ||
    birthDate == ""
  ) {
    return "-";
  }

  let type = "a";
  let age = moment().diff(moment(birthDate, "DD-MM-YYYY"), "years");

  if (age == 0) {
    type = "m";
    age = moment().diff(moment(birthDate, "DD-MM-YYYY"), "months");
  }
  if (age == 0) {
    type = "d";
    age = moment().diff(moment(birthDate, "DD-MM-YYYY"), "days");
  }

  if (age < 0 || Number.isNaN(age) || age > 121) {
    return false;
  } else {
    age = `${age} ${type}`;
    return age;
  }
}

export function RemovePontos(val) {
  var ret = val;
  if (val != null && val != "") ret = val.replace(/[^\d]+/g, "");
  return ret;
}

export function RemoveVirgulas(val) {
  var ret = val;
  if (val != null && val != "") ret = val.replace(",", "");
  return ret;
}

export function FormatValue(val) {
  if (val == null || val == "") return false;

  val = String(val);
  var parts = val.split("");
  if (val.length == 1) return val;
  else if (val.length == 2) return `0.${parts[0]}${parts[1]}`;
  else if (val.length == 3) return `${parts[0]}.${parts[1]}${parts[2]}`;
}

export function FormatValueVirgulas(val) {
  if (val == null || val == "") return false;

  val = String(val);
  var parts = val.split("");
  if (val.length == 1) return val;
  else if (val.length == 2) return `${parts[0]},${parts[1]}`;
  else if (val.length == 3) return `${parts[0]},${parts[1]}${parts[2]}`;
  else if (val.length == 4)
    return `${parts[0]}${parts[1]},${parts[2]}${parts[3]}`;
}

export function HexToRgbA(hex) {
  //Convert color hex to rgba
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${0.1})`;
}

export function GetColorAvaliation(value) {
  var color = "";
  if (value == 0) color = Colors.brand_gray;
  else if (value > 0 && value <= 9) color = Colors.brand_red;
  else if (value >= 10 && value <= 12) color = Colors.brand_orange;
  else if (value >= 13 && value <= 14) color = Colors.brand_yellow;
  else if (value >= 15 && value <= 18) color = Colors.brand_green;
  else if (value >= 19) color = Colors.brand_blue;
  return color;
}

export function ToggleFullScreen() {
  if (
    !document.fullscreenElement && // alternative standard method
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

export function ConvertRuntime(min) {
  if (min != 0) {
    let h = Math.floor(min / 60);
    let m = min % 60;
    h = h < 10 ? "0" + h + "h" : h + "h";
    m = m < 10 ? "0" + m + "m" : m + "m";
    if (h == "00h") h = "";
    if (m == "00m") m = "";
    if (h == "" && m != "00m") m = `${min % 60} min`;
    // console.log("================");
    // console.log("h " + h);
    // console.log("m " + m);
    // console.log("================");
    return `${h} ${m}`;
  }
}

export function ConvertToMinutes(type, val) {
  switch (type) {
    case "hours":
      return val * 60;
    case "days":
      return val * 1440;
    default:
      return val;
  }
}

export function GetColorBattery(val) {
  if (val > 0 && val < 20) return Colors.brand_red;
  if (val >= 20 && val < 50) return Colors.brand_yellow;
  if (val >= 50) return Colors.brand_green;
}

export function GetTag(value, isBefore, current) {
  if (current == "suspend") return "suspend";
  if (current == "realized") return "realized";
  else if (parseInt(value) < 0) return "delayed";
  else if (!isBefore) return "delayed";
  else if (current == "soon") return "soon";
  //else return "realized";
  //if (value > 0) return "";
}

export function GetTagColor(tag) {
  switch (tag) {
    case "realized":
      return "#DBDBDB";
    case "soon":
      return Colors.brand_yellow;
    case "delayed":
      return "#FF7B49";
    case "suspend":
      return Colors.darkest_gray;
    default:
      return "transparent";
  }
}

export function GetTagName(tag, tag_color, isProgressive = false) {
  if (tag == "realized" && tag_color == "#FF7B49")
    return "Atrasada / Incorreta";
  if (tag == "agora") return "agora";
  switch (tag) {
    case "realized":
      return "Realizada";
    case "soon":
      return "Próxima";
    case "delayed":
      return isProgressive ? "Atrasada" : "Atrasada / Incorreta";
    case "suspend":
      return "Suspenso";
    default:
      return "";
  }
}

export function NormalizeWord(word) {
  return word
    .toString()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function VerifyMatchesText(text, array) {
  return array.filter((item) => {
    const normalizeQuery = NormalizeWord(text);

    if (typeof item === "string") {
      if (NormalizeWord(item).includes(normalizeQuery)) return item;
    }

    return Object.values(item).some((val) => {
      if (val == undefined || val == null) return false;
      const normalize = NormalizeWord(val);
      if (normalize.includes(normalizeQuery)) return item;
    });
  });
}

export function convertTimeToHours(time) {
  var parts = time.split(":");
  var hours = parseInt(parts[0], 10);
  var minutes = parseInt(parts[1], 10);
  var seconds = parseInt(parts[2], 10);

  var totalHours = hours + minutes / 60 + seconds / 3600;
  return totalHours;
}

export function convertHoursToTime(hours) {
  var totalSeconds = Math.round(hours * 3600);
  var hh = Math.floor(totalSeconds / 3600);
  var mm = Math.floor((totalSeconds % 3600) / 60);
  var ss = totalSeconds % 60;
  return [hh, mm, ss].map((v) => v.toString().padStart(2, "0")).join(":");
}

export function dynamicMask(newState) {
  let { value } = newState;

  // Remove non-numeric characters
  value = value.replace(/[^0-9]/g, "");

  // Change mask based on length
  if (value.length <= 2) {
    return { ...newState, mask: "9,99" };
  } else if (value.length <= 3) {
    return { ...newState, mask: "99,99" };
  } else {
    return { ...newState, mask: "999,99" };
  }
}

export function adjustSeconds(time, seconds, operation) {
  const parts = time.split(":").map((part) => Number(part));
  const timeInSeconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
  if (isNaN(timeInSeconds)) {
    return "Data inválida";
  }
  const newTimeInSeconds =
    timeInSeconds + (operation === "subtract" ? -seconds : seconds);

  const newHours = Math.floor(newTimeInSeconds / 3600);
  const newMinutes = Math.floor((newTimeInSeconds - newHours * 3600) / 60);
  const newSeconds = newTimeInSeconds - newHours * 3600 - newMinutes * 60;
  return [newHours, newMinutes, newSeconds]
    .map((part) => part.toString().padStart(2, "0"))
    .join(":");
}

export const createStripePattern = (color) => {
  const canvas = document.createElement("canvas");
  canvas.width = 12;
  canvas.height = 12;
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 12, 12);

  ctx.strokeStyle = "black";
  ctx.lineWidth = 0.5;

  ctx.setLineDash([]);

  ctx.beginPath();
  ctx.moveTo(0, 12);
  ctx.lineTo(12, 0);
  ctx.stroke();

  return ctx.createPattern(canvas, "repeat");
};

export function IncidenceStageColor(index) {
  var color = "";

  if (index == 0) color = Colors.brand_blue;
  else if (index == 1) color = Colors.brand_green;
  else if (index == 2) color = Colors.brand_yellow;
  else if (index == 3) color = Colors.brand_orange;
  else if (index == 4) color = Colors.brand_red;
  else if (index == 5) color = Colors.brand_blue;
  else if (index == 6) color = "#7B69DF";
  else if (index == 7) color = Colors.brand_gray;

  return color;
}

export function DeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function resizeChartBasedOnWidth() {
  const width = window.innerWidth;
  if (width < 485) {
    return 300;
  } else if (width < 845) {
    return 200;
  } else if (width < 1500) {
    return 150;
  }
  return 100;
}

export function resizeBmiChartBasedOnWidth() {
  const width = window.innerWidth;
  if (width < 485) {
    return 300;
  } else if (width < 845) {
    return 200;
  } else if (width < 1500) {
    return 100;
  }
  return 80;
}

export function resizePatientOverviewChartBasedOnWidth() {
  const width = window.innerWidth;
  if (width < 485) {
    return 300;
  } else if (width <= 1366) {
    return 220;
  } else if (width <= 1920) {
    return 150;
  }
  return 100;
}

export function getRandomColor() {
  let hue, saturation, lightness;

  do {
    hue = Math.floor(Math.random() * 360);
  } while (hue > 20 && hue < 40);

  saturation = Math.floor(Math.random() * 50) + 50;

  lightness = Math.floor(Math.random() * 40) + 30;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function GetOrderIndexColor(index) {
  var color = "";

  if (index == 0) color = Colors.brand_red;
  else if (index == 1) color = Colors.brand_orange;
  else if (index == 2) color = Colors.brand_yellow;
  else if (index == 3) color = Colors.brand_green;
  else if (index == 4) color = Colors.brand_blue;
  else if (index == 5) color = Colors.brand_pink;
  else if (index == 6) color = Colors.brand_teal;
  else if (index == 7) color = Colors.brand_purple;
  else if (index == 7) color = Colors.light_red;
  else if (index == 8) color = Colors.light_orange;
  else if (index == 9) color = Colors.light_yellow;
  else if (index == 10) color = Colors.light_blue;
  else if (index == 11) color = Colors.light_pink;
  else color = getRandomColor();
  return color ? color : getRandomColor();
}

export function secondsToHoursMinutes(seconds, useComma = false) {
  if (!seconds) {
    return "0m";
  }

  var days = Math.floor(seconds / 86400); // 86400 segundos em um dia
  var hours = Math.floor((seconds % 86400) / 3600); // Restante para horas
  var minutes = Math.floor((seconds % 3600) / 60); // Restante para minutos

  var separator = useComma ? ", " : " ";
  var parts = [];
  if (days > 0) parts.push(days + "d");
  if (hours > 0) parts.push(hours + "h");
  if (minutes > 0) parts.push(minutes + "m");

  return parts.length > 0 ? parts.join(separator) : "0m";
}

export function secondsToFullTime(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  return [hours, minutes, remainingSeconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .join(":");
}

export function secondsToDays(seconds) {
  var days = seconds / 86400;
  return parseInt(days.toFixed(2)); // Rounds to 2 decimal places
}

export function CalculateArea(width, height) {
  var ret = "";
  if (width != "null" && height != "null") {
    ret = parseFloat(width) * parseFloat(height);
    ret = String(ret.toFixed(2));
  }
  return ret;
}

export function findNonNullRanges(array) {
  const ranges = [];
  let start = null;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null && start === null) {
      start = i == 0 ? 0 : i + 1;
    } else if (array[i] === null && start !== null) {
      if (start == i) {
        ranges.push([start / array.length - 0.1, i / array.length]);
      } else {
        ranges.push([start / array.length, i / array.length]);
      }
      start = null;
    }
  }
  if (start !== null) {
    ranges.push([start / array.length, 1]);
  }
  return ranges;
}

export function createChartGradient(chartInstance, initialColor, finalColor) {
  if (chartInstance && chartInstance.ctx) {
    const ctx = chartInstance.ctx;

    for (let dataset of chartInstance.data.datasets) {
      if (!dataset?.label || dataset?.label == "mock") {
        continue;
      }
      const gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
      const colorRanges = findNonNullRanges(dataset.data);
      for (let range of colorRanges) {
        if (range.length == 2) {
          let gradientStart = Math.max(0, Math.min(range[0], 0.9));
          let gradientEnd = Math.max(0, Math.min(range[1], 1));
          if (dataset.data.length <= 1) {
            gradientStart = 0;
            gradientEnd = 0;
          }
          gradient.addColorStop(gradientStart, initialColor);
          gradient.addColorStop(Math.floor(gradientEnd * 10) / 10, finalColor);
        }
      }
      dataset.borderColor = gradient;
      dataset.backgroundColor = gradient;
    }
    chartInstance.update();
  }
}

export function paginationMaxItemsPerWidth() {
  if (window.innerWidth <= 576) {
    return 0;
  } else if (window.innerWidth <= 768) {
    return 1;
  } else if (window.innerWidth <= 992) {
    return 2;
  } else {
    return 3;
  }
}

export function monthAbbToFullDictionary() {
  return {
    Jan: "Janeiro",
    Fev: "Fevereiro",
    Mar: "Março",
    Abr: "Abril",
    Mai: "Maio",
    Jun: "Junho",
    Jul: "Julho",
    Ago: "Agosto",
    Set: "Setembro",
    Out: "Outubro",
    Nov: "Novembro",
    Dez: "Dezembro",
  };
}

export function userWithoutPermission(user) {
  return (
    (user.dados.permission_id > 3 && user.dados.permission_id < 6) ||
    user.dados.permission_id == 8
  );
}

export function userWithoutPermissionIncludingNurse(user) {
  return userWithoutPermission(user) && user.dados.permission_id !== 3;
}

export function userHasPermissionExcludingNurse(user) {
  return userHasPermission(user) && user.dados.permission_id !== 3;
}

export function userHasPermission(user) {
  return (
    (user.dados.permission_id <= 3 || user.dados.permission_id >= 6) &&
    user.dados.permission_id !== 8
  );
}

export function userIsAdmin(user) {
  return user.dados.permission_id == 1 || user.dados.permission_id == 6;
}

export function userHasGeneralPermissions(user) {
  return (
    (user.dados.permission_id <= 2 || user.dados.permission_id >= 6) &&
    user.dados.permission_id !== 8
  );
}

export function hospitalIsFullType(hospitalPlan) {
  return hospitalPlan.dados.type !== 2;
}

export function buildPagination(currentPage, lastPage, paginationHandler) {
  var pagination = [];
  for (var i = currentPage; i < currentPage + 5; i++) {
    if (i == currentPage && currentPage > 2) {
      pagination.push(currentPage - 2);
    }
    if (i == currentPage && currentPage > 1) {
      pagination.push(currentPage - 1);
    }

    if (i <= lastPage) {
      pagination.push(i);
    }
  }
  paginationHandler(pagination);
}

export function renderDisplay(lastPage, qtdDisplay, currentPage, totalResults) {
  var n = 0;

  if (lastPage > 1) {
    n = qtdDisplay * currentPage;
    if (currentPage == lastPage) n = totalResults;
  } else {
    n = totalResults;
  }

  return n;
}

export function setDisplay(qtd, curretPageHandler, qtdDisplayHandler) {
  curretPageHandler(1);
  qtdDisplayHandler(qtd);
}
