import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Col,
  Container,
  Row,
  Form,
  FormControl,
  InputGroup,
  Button,
  Dropdown,
  DropdownToggle,
  Spinner,
} from "react-bootstrap";
import MainNavbar from "../../components/Nav/MainNavbar";
import PwStrengthMeter from "../../components/PwStrengthMeter";
import InputMask from "react-input-mask";
import Select from "react-select";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "./styles.css";

import api from "../../services/api";
import { User_Login } from "../../store/actions/user";
import { Show_Alert } from "../../store/actions/alert";
import { CalculateAge } from "../../functions/utils";
import TempoSonoro from "./Tabs/TempoSonoro/TempoSonoro";
import Usuarios from "./Tabs/Usuarios/Usuarios";
import Unidades from "./Tabs/Unidades/Unidades";
import ProtocoloDecubito from "./Tabs/ProtocoloDecubito/ProtocoloDecubito";
import PainelSensores from "./Tabs/PainelSensores/PainelSensores";
import { renderPhoto } from "../../components/Dropdowns/ProfileDropdown";
import TempoSuspensao from "./Tabs/TempoSuspensao/TempoSuspensao";
import ConfirmacaoLesao from "./Tabs/ConfirmacaoLesao/ConfirmacaoLesao";
import EscalaPlantoes from "./Tabs/EscalaPlantoes/EscalaPlantoes";
import AutomacaoDeEmails from "./Tabs/AutomacaoDeEmails/AutomacaoDeEmails";

// Itens da navegação lateral
var buttons = [
  {
    icon: "edit",
    name: "Minha conta",
    planType: -1,
  },
  {
    icon: "user-group",
    name: "Usuários",
    planType: -1,
  },
  // {
  //   icon: "nursing",
  //   name: "Enfermagem",
  // },
  {
    icon: "hospital",
    name: "Unidades",
    planType: -1,
  },
  // {
  //   icon: "description",
  //   name: "Motivos",
  // },
  {
    icon: "bell",
    name: "Tempo sonoro dos avisos",
    planType: 1,
  },
  {
    icon: "bell-off",
    name: "Tempo de suspensão",
    planType: 1,
  },
  {
    icon: "preveni-icon",
    name: "Painel de sensores",
    planType: 1,
  },
  {
    icon: "clock-cicle",
    name: "Protocolo de decúbito",
    planType: 1,
  },
  {
    icon: "band-aid",
    name: "Confirmação de lesão",
    planType: -1,
  },
  {
    icon: "sortby",
    name: "Escala de plantões",
    planType: 1,
  },
  {
    icon: "email",
    name: "Automação de e-mails",
    planType: 1,
  },
];

function MinhaConta({ history }) {
  // Dados de usuário
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);

  // console.log("User units: ");
  // console.log(userUnit);

  console.log("USER:");
  console.log(user);

  // Exibição da página
  const cameraIcon = <Icon color={"white"} size={20} icon="camera" />;
  const loadingIcon = (
    <Spinner animation="border" role="status" variant="light" size="sm" />
  );
  const [aba, setAba] = useState("dados");
  const [hovered, setHovered] = useState(null);
  const [tab, setTab] = useState(0);
  const [photoDrop, setPhotoDrop] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("");
  const [loadingPhoto, setLoadingPhoto] = useState(cameraIcon);
  const inputPhoto = useRef(null);

  // Erros
  const [erroEmail, setErroEmail] = useState(false);
  const [erroAuth, setErroAuth] = useState(false);
  const [erroCpf, setErroCpf] = useState(false);

  // Requests + Listas
  const [optionsFormacao, setOptionsFormacao] = useState([]);
  const [optionsLocais, setOptionsLocais] = useState([]);
  const [optionsHospitais, setOptionsHospitais] = useState([]);
  const [optionsEstados, setOptionsEstados] = useState([]);
  const [optionsCidades, setOptionsCidades] = useState([]);
  const [optionsUnidades, setOptionsUnidades] = useState([]);

  // Formatting
  var fBday =
    user.dados.date_birth != "Invalid date"
      ? user.dados.date_birth.split("-")
      : null;

  const defaultSelect = { label: "Selecione", value: 0, key: 0 };
  const otherSelect = { label: "Outro", value: 999, key: 999 };

  // Alteração de dados
  const [photo, setPhoto] = useState(
    user.dados.image != null
      ? user.dados.url_image
      : require("../../assets/img/icon_user.png")
  );
  const [nome, setNome] = useState(user.dados.name);
  const [email, setEmail] = useState(user.dados.email);
  const [cpf, setCpf] = useState(
    user.dados.cpf != undefined ? user.dados.cpf : ""
  );
  const [data, setData] = useState(
    fBday != null ? fBday[2] + "/" + fBday[1] + "/" + fBday[0] : ""
  );
  const [sexo, setSexo] = useState(
    user.dados.gender != null ? user.dados.gender : null
  );
  const [formacao, setFormacao] = useState(defaultSelect);
  const [formacaoOutro, setFormacaoOutro] = useState("");
  const [tipoLocal, setTipoLocal] = useState(undefined);
  const [local, setLocal] = useState(undefined);
  const [hospital, setHospital] = useState(undefined);
  const [nomeLocal, setNomeLocal] = useState(undefined);
  const [autenticacao, setAutenticacao] = useState(
    user.dados.permission != null ? "999999" : ""
  );
  const [registro, setRegistro] = useState(
    user.dados.professional_record != null
      ? user.dados.professional_record
      : null
  );
  const [estado, setEstado] = useState(undefined);
  const [cidade, setCidade] = useState(undefined);
  const [unidade, setUnidade] = useState(undefined);
  const [permissao, setPermissao] = useState(user.dados.permission_id);

  // Alteração de senha
  const [password, setPassword] = useState(undefined);
  const [showPwd, setShowPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [newPwd, setNewPwd] = useState("");
  const [levelPwd, setLevelPwd] = useState(0);

  // Flags
  const [defaultAuth, setDefaultAuth] = useState(user.dados.permission != null);

  // Carregar dados da API
  useEffect(() => {
    loadFormacoes().then(() => {
      loadLocais().then(() => {
        loadHospitais().then(() => {
          loadStates().then(() => {});
        });
      });
    });
  }, []);

  // Recarregar cidades quando muda estado
  useEffect(() => {
    loadCities();
  }, [estado]);

  // Redefinir campos quando muda hospital + recarregar unidades
  useEffect(() => {
    loadUnits();
  }, [hospital]);

  // Redefinir campos quando insere código de autenticação
  useEffect(() => {
    if (!defaultAuth) {
      // Busca dados do código na API
      if (autenticacao.trim().length == 6) {
        api
          .get(`/verify-code/${autenticacao}`)
          .then((res) => {
            console.log("Resultado autenticação:");
            console.log(res.data);
            setErroAuth(false);
            var dados = res.data.unit;

            setPermissao(res.data.permission.id);
            setUnidade({
              label: dados.name,
              value: dados.id,
              unit: dados,
              key: dados.id,
            });
            setHospital({
              label: dados.hospitals.name,
              value: dados.hospital_id,
              unit: dados.hospitals,
            });
            setCidade({
              label: dados.hospitals.city.name,
              value: dados.hospitals.city.id,
              cidade: dados.hospitals.city,
            });
            setEstado({
              label: dados.hospitals.city.state.name,
              value: dados.hospitals.city.state.id,
              state: dados.hospitals.city.state,
            });
          })
          .catch((err) => {
            console.log("Load Autenticação error " + err);
            dispatch(
              Show_Alert({
                type: "error",
                msg: "Não foi possível obter os dados. Verifique sua conexão.",
              })
            );
            setErroAuth(true);
          });
      } else {
        setErroAuth(false);
        setPermissao(user.dados.permission_id);
      }
    }
  }, [autenticacao]);

  // Verificar se cpf existe em outro cadastro quando modificado
  useEffect(() => {
    setErroCpf(false);
    if (cpf.length > 0 && cpf.trim().length > 0) {
      api
        .get(`/users/verify-cpf/${cpf}`)
        .then((res) => {
          if (res.data == false) {
            setErroCpf(true);
          } else {
            setErroCpf(false);
          }
        })
        .catch((err) => {
          console.log("Verificar CPF existente error " + err);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível obter os dados. Verifique sua conexão. (GET CPF)",
            })
          );
        });
    } else {
      setErroAuth(false);
      setPermissao(user.dados.permission_id);
    }
  }, [cpf]);

  // Verifica se email é válido
  useEffect(() => {
    setErroEmail(!VerifyEmail(email));
  }, [email]);

  // Funções para busca de dados da API
  function loadFormacoes() {
    setOptionsFormacao([]);

    // Get formações
    return api
      .get(`/qualifications`)
      .then((res) => {
        let selected = defaultSelect;
        let formacoes = res.data.map((f, i) => {
          if (formacao != undefined && formacao != defaultSelect) {
            if (f.id == formacao.value) {
              selected = { label: f.name, value: f.id, key: f.id };
            }
          } else {
            if (
              user.dados.qualification_id != null &&
              f.id == user.dados.qualification_id
            ) {
              selected = { label: f.name, value: f.id, key: f.id };
            }
          }
          return { label: f.name, value: f.id, key: f.id };
        });
        formacoes.push(otherSelect);
        setFormacao(selected);
        setOptionsFormacao(formacoes);
      })
      .catch((err) => {
        console.log("Load Formações error " + err);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível obter os dados. Verifique sua conexão.",
          })
        );
      });
  }

  function loadLocais() {
    setOptionsLocais([]);

    // Get locais de trabalho
    return api
      .get(`/workplaces`)
      .then((res) => {
        let selected = defaultSelect;
        let locais = res.data.map((l, i) => {
          if (tipoLocal != undefined && tipoLocal != defaultSelect) {
            if (l.id == tipoLocal.value) {
              selected = { label: l.name, value: l.id, key: l.id };
            }
          } else {
            if (
              user.dados.type_workplace_id != null &&
              l.id == user.dados.type_workplace_id
            ) {
              selected = { label: l.name, value: l.id, key: l.id };
            }
          }
          return { label: l.name, value: l.id, key: l.id };
        });
        locais.push(otherSelect);
        setTipoLocal(selected);
        setOptionsLocais(locais);
      })
      .catch((err) => {
        console.log("Load Locais error " + err);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível obter os dados. Verifique sua conexão.",
          })
        );
      });
  }

  function loadHospitais() {
    // Get nome do local de trabalho (hospital)
    setOptionsHospitais([]);

    return api
      .get(`/hospitals/`)
      .then((res) => {
        let selected = defaultSelect;
        let nomes = res.data.map((l, i) => {
          if (hospital != undefined) {
            if (l.id == hospital.value) {
              selected = { label: l.name, value: l.id, key: l.id };
            }
          } else {
            if (
              user.dados.units != null &&
              l.id == user.dados.units.hospital_id
            ) {
              selected = { label: l.name, value: l.id, key: l.id };
            }
          }
          return { label: l.name, value: l.id, key: l.id };
        });
        nomes.push(otherSelect);
        setHospital(selected);
        setOptionsHospitais(nomes);
      })
      .catch((err) => {
        console.log("Load Hospitais error " + err);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível obter os dados. Verifique sua conexão.",
          })
        );
      });
  }

  function loadUnits() {
    setOptionsUnidades([]);
    if (hospital == undefined || hospital.value == 0) {
      setUnidade(undefined);
      return false;
    }
    // Get unidades
    return api
      .get(`/hospitals/${hospital.value}/units`)
      .then((res) => {
        let selected = undefined;
        let units = res.data.map((u, i) => {
          if (unidade != undefined) {
            if (u.id == unidade.value) {
              selected = { label: u.name, value: u.id, unit: u, key: u.id };
            }
          } else {
            if (user.dados.unit_id != null && u.id == user.dados.unit_id) {
              selected = { label: u.name, value: u.id, unit: u, key: u.id };
            }
          }
          return { label: u.name, value: u.id, unit: u, key: u.id };
        });
        console.log("units");
        console.log(units);
        setOptionsUnidades(units);
        setUnidade(selected);
      })
      .catch((err) => {
        console.log("Load Unidades error " + err);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível obter os dados. Verifique sua conexão.",
          })
        );
      });
  }

  function loadStates() {
    setOptionsEstados([]);

    // Get states
    return api
      .get(`/states`)
      .then((res) => {
        let selected = defaultSelect;
        let states = res.data.map((s, i) => {
          if (estado != undefined) {
            if (s.id == estado.value) {
              selected = { label: s.name, value: s.id, key: s.id };
            }
          } else {
            if (user.dados.city != null && s.id == user.dados.city.state.id) {
              selected = { label: s.name, value: s.id, key: s.id };
            }
          }
          return { label: s.name, value: s.id, key: s.id };
        });
        setOptionsEstados(states);
        setEstado(selected);
      })
      .catch((err) => {
        console.log("Load States error " + err);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível obter os dados. Verifique sua conexão.",
          })
        );
      });
  }

  function loadCities() {
    setOptionsCidades([]);

    if (estado != undefined) {
      // Get cities
      return api
        .get(`/states/${estado.value}/cities`)
        .then((res) => {
          let selected = defaultSelect;
          let cities = res.data.map((c, i) => {
            if (cidade != undefined) {
              if (c.id == cidade.value) {
                selected = { label: c.name, value: c.id, key: c.id };
              }
            } else {
              if (user.dados.city != null && c.id == user.dados.city.id) {
                selected = { label: c.name, value: c.id, key: c.id };
              }
            }
            return { label: c.name, value: c.id, key: c.id };
          });
          setOptionsCidades(cities);
          setCidade(selected);
        })
        .catch((err) => {
          console.log("Load Cities error " + err);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível obter os dados. Verifique sua conexão.",
            })
          );
        });
    }
  }

  function VerifyEmail() {
    if (email.length > 0) {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var res = new RegExp(regex).test(email);
      return res;
    } else {
      return false;
    }
  }

  // Salvar dados
  function SalvarAlteracoes() {
    if (nome == "" || email == "" || sexo == "") {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Por favor, preencha os campos nome, email e sexo.",
        })
      );
      // alert('Por favor', 'Preencha os campos nome, email e sexo.');
      return false;
    }

    setLoadingBtn(loadingIcon);

    // Salva unidades
    var arrayUnits = [];
    optionsUnidades.map((item) => {
      if (item.unit.id != unidade?.value) {
        arrayUnits.push(item.unit.id);
      }
    });

    // Formatação da data de nascimento
    var dataF = null;
    if (data != "" && data != null && data.length == 10) {
      let data_s = data.split("/");
      dataF = data_s[2] + "-" + data_s[1] + "-" + data_s[0];
    }

    api
      .put(`/users/${user.dados.id}/own`, {
        name: nome,
        email: email,
        cpf: cpf,
        date_birth: dataF,
        qualification_id: formacao.value,
        qualification: formacaoOutro.trim().length > 0 ? formacaoOutro : null,
        type_workplace_id: tipoLocal.value,
        type_workplace:
          local != null && local != undefined && local.trim().length > 0
            ? local
            : null,
        gender: sexo,
        professional_record: registro,
        unit_id: unidade ? unidade.value : user.dados.unit_id,
        permission_id: permissao,
        units: arrayUnits,
        hospital_id: hospital.value,
        city_id: cidade.value,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Editar user");
          console.log(response.data);
          var dados = {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            cpf: response.data.cpf,
            permission_id: response.data.permission_id,
            permission: response.data.permission,
            image: response.data.image,
            url_image: response.data.url_image,
            gender: response.data.gender,
            date_birth: response.data.date_birth,
            unit_id: response.data.unit_id,
            units: response.data.units,
            qualification_id: response.data.qualification_id,
            professional_record: response.data.professional_record,
            type_workplace_id: response.data.type_workplace_id,
            hospital_id: response.data.hospital_id,
            city: response.data.city,
          };

          //Salva dados do user no redux
          dispatch(User_Login(dados));

          //Salva as unidades localmente
          if (response.data.units != null) {
            var arrayUnitsSave = [];
            optionsUnidades.map((item) => {
              arrayUnitsSave.push({ name: item.label, id: item.value });
            });

            dispatch({ type: "USER_UNIT_SET", list: arrayUnitsSave });

            // Verifica se unidade selecionada anteriormente ainda está disponível
            if (
              arrayUnitsSave.find((u) => u.id == unit.selecionado?.id) ==
              undefined
            ) {
              dispatch({ type: "UNIT_SET", selecionado: arrayUnitsSave[0] });
            } else {
              var match = arrayUnitsSave.find(
                (u) => u.id == unit.selecionado?.id
              );
              dispatch({ type: "UNIT_SET", selecionado: match });
            }
          } else {
            //Reseta as unidades localmente
            dispatch({ type: "USER_UNIT_CLEAR" });
            dispatch({ type: "UNIT_CLEAR" });
          }

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Os dados foram alterados com sucesso.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("ERRO AO ENVIAR DADOS");
        console.log(error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar os dados. Verifique sua conexão.",
          })
        );
      })
      .finally(() => setLoadingBtn(""));
  }

  // Salvar senha nova
  function SalvarSenha() {
    setLoadingBtn(loadingIcon);

    if (
      password == undefined ||
      password.trim().length == 0 ||
      newPwd == undefined ||
      newPwd.trim().length == 0
    ) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Por favor, preencha os campos nome, email e sexo.",
        })
      );
      // alert('Por favor', 'Preencha os campos nome, email e sexo.');
      setLoadingBtn("");
      return false;
    }

    // Se tem as senhas inseridas e a senha atual é válida
    // Envia para api
    api
      .put(`/users/${user.dados.id}/own`, {
        old_password: password,
        password: newPwd,
      })
      .then((response) => {
        if (response.status == 200) {
          setAba("dados");
          setLevelPwd(0);
          setNewPwd("");
          var dados = {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            cpf: response.data.cpf,
            permission_id: response.data.permission_id,
            permission: response.data.permission,
            image: response.data.image,
            url_image: response.data.url_image,
            gender: response.data.gender,
            date_birth: response.data.date_birth,
            unit_id: response.data.unit_id,
            units: response.data.units,
            qualification_id: response.data.qualification_id,
            professional_record: response.data.professional_record,
            type_workplace_id: response.data.type_workplace_id,
            hospital_id: response.data.hospital_id,
            city: response.data.city,
          };

          //Salva dados do user no redux
          dispatch(User_Login(dados));

          //Salva as unidades localmente
          if (response.data.units != null) {
            var arrayUnitsSave = [];
            optionsUnidades.map((item) => {
              arrayUnitsSave.push({ name: item.label, id: item.value });
            });
            dispatch({ type: "USER_UNIT_SET", list: arrayUnitsSave });
            dispatch({ type: "UNIT_SET", selecionado: arrayUnitsSave[0] });
          } else {
            //Reseta as unidades localmente
            dispatch({ type: "USER_UNIT_CLEAR" });
            dispatch({ type: "UNIT_CLEAR" });
          }

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Os dados foram alterados com sucesso.",
            })
          );
        }
      })
      .catch((error) => {
        // console.log('ERRO AO ENVIAR DADOS');
        // console.log(error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "A senha atual inserida é incorreta.",
          })
        );
      })
      .finally(() => setLoadingBtn(""));
  }

  // Trigger de mudança de foto
  const onPhotoChange = () => {
    inputPhoto.current.click();
  };

  // Fazer upload da imagem
  function uploadImage() {
    let file = inputPhoto.current.files[0];

    setLoadingPhoto(loadingIcon);

    var formData = new FormData();
    formData.append("image", file);
    formData.append("filename", file.name);

    api
      .post(`/users/${user.dados.id}/image`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData.boundary}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          var dados = {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            cpf: response.data.cpf,
            permission_id: response.data.permission_id,
            permission: response.data.permission,
            image: response.data.image,
            url_image: response.data.url_image,
            gender: response.data.gender,
            date_birth: response.data.date_birth,
            unit_id: response.data.unit_id,
            units: response.data.units,
            qualification_id: response.data.qualification_id,
            professional_record: response.data.professional_record,
            type_workplace_id: response.data.type_workplace_id,
            hospital_id: response.data.hospital_id,
            city: response.data.city,
          };

          //Salva dados do user no redux
          dispatch(User_Login(dados));

          // Atualiza imagem
          // user.dados.url_image = dados.url_image;
          setPhoto(dados.url_image);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "A imagem foi alterada com sucesso.",
            })
          );
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Algo não saiu como o esperado. Tente novamente mais tarde.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Upload Image " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar a imagem. Verifique sua conexão.",
          })
        );
      })
      .finally(() => setLoadingPhoto(cameraIcon));
  }

  // Remover foto
  function removePhoto() {
    setLoadingPhoto(loadingIcon);

    api
      .delete(`/users/${user.dados.id}/image`)
      .then((response) => {
        if (response.status == 200) {
          var dados = {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            cpf: response.data.cpf,
            permission_id: response.data.permission_id,
            permission: response.data.permission,
            image: response.data.image,
            url_image: response.data.url_image,
            gender: response.data.gender,
            date_birth: response.data.date_birth,
            unit_id: response.data.unit_id,
            units: response.data.units,
            qualification_id: response.data.qualification_id,
            professional_record: response.data.professional_record,
            type_workplace_id: response.data.type_workplace_id,
            hospital_id: response.data.hospital_id,
            city: response.data.city,
          };

          //Salva dados do user no redux
          dispatch(User_Login(dados));

          // Atualiza imagem
          user.dados.url_image = dados.url_image;
          setPhoto(require("../../assets/img/icon_user.png"));

          dispatch(
            Show_Alert({
              type: "success",
              msg: "A imagem foi alterada com sucesso.",
            })
          );
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Algo não saiu como o esperado. Tente novamente mais tarde.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Delete Image " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível remover a imagem. Verifique sua conexão.",
          })
        );
      })
      .finally(() => setLoadingPhoto(cameraIcon));
  }

  function optionCanShow(permission, name) {
    if (
      name == "Usuários" ||
      name == "Tempo sonoro dos avisos" ||
      name == "Unidades" ||
      name == "Protocolo de decúbito" ||
      name == "Confirmação de lesão" ||
      name == "Escala de plantões" ||
      name == "Automação de e-mails"
    ) {
      if (permission !== 1 && permission !== 6) return false;
    }
    if (
      name == "Painel de sensores" &&
      ((permission > 1 && permission < 6) || permission == 8)
    ) {
      return false;
    }

    return true;
  }

  return (
    <div>
      <MainNavbar history={history} screen={"settings"} />

      <Container
        fluid
        className="main-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <input
          type="file"
          id="photo"
          ref={inputPhoto}
          style={{ display: "none" }}
          onChange={(e) => uploadImage(e)}
        />

        <h2 className="title mb-4">Configurações</h2>
        <Row className="mb-2">
          <Col xs={12} md={4}>
            {buttons.map((item, index) => {
              if (
                item.planType !== -1 &&
                item.planType !== hospitalPlan.dados.type
              ) {
                return;
              }
              if (
                item.name == "Usuários" ||
                item.name == "Tempo sonoro dos avisos" ||
                item.name == "Tempo de suspensão" ||
                item.name == "Unidades" ||
                item.name == "Protocolo de decúbito" ||
                item.name == "Confirmação de lesão" ||
                item.name == "Automação de e-mails"
              ) {
                if (
                  user.dados.permission_id !== 1 &&
                  user.dados.permission_id !== 6
                )
                  return false;
              }

              return optionCanShow(user.dados.permission_id, item.name) ? (
                <button
                  onMouseDown={() => setTab(index)}
                  className={`btn-config ${tab == index ? "btn-active" : ""}`}
                  data-testid={item.name}
                >
                  <Icon
                    color={tab == index ? Colors.brand_blue : Colors.dark_gray}
                    size={18}
                    icon={item.icon}
                    className="mr-3"
                  />
                  {item.name}
                </button>
              ) : (
                false
              );
            })}
          </Col>

          <Col xs={12} md={8} className="mt-4 mt-md-0">
            {tab == 0 && aba == "dados" && (
              <>
                <p className="text-left title-tab">Minha conta</p>

                <div className="px-2">
                  <Row
                    className="w-100"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></Row>

                  <Row className="tabs mt-4">
                    <Col xs={6} md={4} lg={4} onClick={() => setAba("dados")}>
                      <h6
                        className={aba == "dados" ? "active-text" : ""}
                        onClick={() => setAba("dados")}
                      >
                        Dados pessoais
                      </h6>
                    </Col>
                    <Col xs={6} md={4} lg={4} onClick={() => setAba("senha")}>
                      <h6
                        className={aba == "senha" ? "active-text" : ""}
                        onClick={() => setAba("senha")}
                      >
                        Alterar senha
                      </h6>
                    </Col>
                  </Row>

                  <Row className="tab-bar">
                    <Col
                      xs={6}
                      md={4}
                      lg={4}
                      onClick={() => setAba("dados")}
                      className={aba == "dados" ? "active-tab" : ""}
                    />
                    <Col
                      xs={6}
                      md={4}
                      lg={4}
                      onClick={() => setAba("senha")}
                      className={aba == "senha" ? "active-tab" : ""}
                    />
                  </Row>
                </div>

                <Dropdown
                  className="photo-drop"
                  onToggle={() => setPhotoDrop(!photoDrop)}
                >
                  <Dropdown.Toggle
                    style={{ backgroundColor: "#fff", border: "none" }}
                    className="e-caret-hide"
                  >
                    <div
                      className="position-relative c-profile-dropdown"
                      style={{ maxWidth: 96 }}
                    >
                      {renderPhoto(user, "lg")}
                      <button
                        className={photoDrop ? "btn-cam active" : "btn-cam"}
                      >
                        {loadingPhoto}
                      </button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onPhotoChange}>
                      Alterar foto
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removePhoto}>
                      Remover foto
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Form>
                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">Nome</Form.Label>
                        <Form.Control
                          className="input-lg"
                          value={nome}
                          onChange={(v) => setNome(v.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row className="justify-content-between">
                    <Col xs={12} md={6} className="pr-4 pl-0">
                      <Form.Group>
                        <Form.Label className="field-label">E-mail</Form.Label>
                        <Form.Control
                          className={
                            erroEmail && email.trim().length > 0
                              ? "input-lg error-input"
                              : "input-lg"
                          }
                          value={email}
                          onChange={(v) => setEmail(v.target.value)}
                        />
                        {erroEmail && email.trim().length > 0 && (
                          <div className="error-tag ml-1">
                            O e-mail inserido é inválido.
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="pr-0">
                      <Form.Group>
                        <Form.Label className="field-label">CPF</Form.Label>
                        <InputMask
                          className={
                            erroCpf && cpf.trim().length > 0
                              ? "input-lg form-control error-input"
                              : "input-lg form-control"
                          }
                          type="text"
                          mask={"999.999.999-99"}
                          maskChar={null}
                          value={cpf}
                          onChange={(v) => setCpf(v.target.value)}
                        />
                        {erroCpf && cpf.trim().length > 0 && (
                          <div className="error-tag ml-1">
                            Este CPF já foi cadastrado.
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={6} className="pl-0 pr-4">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Data de nascimento
                        </Form.Label>
                        <div className="fake-input">
                          <Icon
                            color={Colors.darker_gray}
                            size={24}
                            icon="calendar"
                            className="mr-3"
                          />
                          <InputMask
                            className="borderless-input pad"
                            mask={"99/99/9999"}
                            maskChar={null}
                            type="text"
                            placeholder="dd/mm/aaaa"
                            value={data}
                            onChange={(e) => {
                              let dateBirth = e.target.value;
                              setData(dateBirth);
                              if (dateBirth.length == 10) {
                                if (!CalculateAge(dateBirth)) {
                                  alert("Data inválida", "Data inválida");
                                  setData(dateBirth);
                                  return false;
                                }
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={6} className="px-0">
                      <Form.Group>
                        <div className="field-label">Sexo</div>
                        <Row className="justify-content-start align-items-center mx-0">
                          <Col xs={6} md={6} lg={4} className="pl-0 pr-2">
                            <div
                              className={
                                sexo === "M"
                                  ? "btn-options active"
                                  : "btn-options"
                              }
                              onClick={() => setSexo("M")}
                            >
                              Masculino
                            </div>
                          </Col>
                          <Col xs={6} md={6} lg={4} className="pr-0 pl-2">
                            <div
                              className={
                                sexo === "F"
                                  ? "btn-options active"
                                  : "btn-options"
                              }
                              onClick={() => setSexo("F")}
                            >
                              Feminino
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Formação
                        </Form.Label>
                        <Select
                          captureMenuScroll={false}
                          classNamePrefix="react-select"
                          placeholder={"Selecionar"}
                          options={optionsFormacao}
                          isClearable={false}
                          value={formacao}
                          onChange={(f) => {
                            setFormacao(f);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  {formacao != undefined && formacao.value == 999 && (
                    <Form.Row>
                      <Col xs={12} md={12} className="px-0">
                        <Form.Group>
                          <Form.Control
                            className="input-lg"
                            value={formacaoOutro}
                            placeholder="Título da formação"
                            onChange={(v) => setFormacaoOutro(v.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  )}

                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Registro Profissional
                        </Form.Label>
                        <Form.Control
                          className="input-lg"
                          value={registro}
                          onChange={(v) => setRegistro(v.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Código de autenticação
                        </Form.Label>
                        <Form.Control
                          className={
                            erroAuth && autenticacao.trim().length > 0
                              ? "input-lg error-input"
                              : "input-lg"
                          }
                          value={autenticacao}
                          type="password"
                          onFocus={() => {
                            setAutenticacao("");
                            setDefaultAuth(false);
                          }}
                          onBlur={() => {
                            if (autenticacao.trim().length == 0) {
                              setAutenticacao(
                                user.dados.permission == null ? "" : "999999"
                              );
                              setDefaultAuth(true);
                            }
                          }}
                          onChange={(v) => {
                            let cod = v.target.value;
                            cod = cod.toUpperCase();
                            setAutenticacao(cod);
                          }}
                        />
                        {erroAuth && autenticacao.trim().length > 0 && (
                          <div className="error-tag ml-1">
                            O código de autenticação informado é inválido.
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">Unidade</Form.Label>
                        <Row className="mx-0">
                          {optionsUnidades.map((unit, i) => {
                            return (
                              <div className="btn-options unidades mb-2 mr-2">
                                {unit.label}
                              </div>
                            );
                          })}

                          {/* {unidade == undefined ||
                            (unidade != undefined &&
                              userUnit.list.map((unit, i) => {
                                if (i === userUnit.list.length - 1) {
                                  return (
                                    <div
                                      // onClick={() => setUnidade(unit)}
                                      // className={
                                      //   unidade == undefined
                                      //     ? "btn-options unidades"
                                      //     : unit.value === unidade.value
                                      //     ? "btn-options unidades active"
                                      //     : "btn-options unidades"
                                      // }
                                      className="btn-options unidades mb-2"
                                    >
                                      {unit.name}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      // onClick={() => setUnidade(unit)}
                                      // className={
                                      //   unidade == undefined
                                      //     ? "btn-options unidades mr-2"
                                      //     : unit.value === unidade.value
                                      //     ? "btn-options unidades active mr-2"
                                      //     : "btn-options unidades mr-2"
                                      // }
                                      className="btn-options unidades mr-2 mb-2"
                                    >
                                      {unit.name}
                                    </div>
                                  );
                                }
                              }))} */}
                        </Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={6} className="pl-0 pr-4">
                      <Form.Group>
                        <Form.Label className="field-label">Estado</Form.Label>
                        <Select
                          captureMenuScroll={false}
                          classNamePrefix="react-select"
                          placeholder={"Selecionar"}
                          options={optionsEstados}
                          isClearable={false}
                          value={estado}
                          onChange={(e) => {
                            setEstado(e);
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} className="px-0">
                      <Form.Group>
                        <div className="field-label">Cidade</div>
                        <Select
                          captureMenuScroll={false}
                          classNamePrefix="react-select"
                          placeholder={"Selecionar"}
                          options={optionsCidades}
                          isClearable={false}
                          value={cidade}
                          onChange={(c) => {
                            setCidade(c);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Form>
              </>
            )}
            {tab == 0 && aba == "senha" && (
              <>
                <p className="text-left title-tab">Minha conta</p>

                <div className="px-2">
                  <Row
                    className="w-100"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></Row>

                  <Row className="tabs mt-4">
                    <Col xs={6} md={4} lg={4} onClick={() => setAba("dados")}>
                      <h6
                        className={aba == "dados" ? "active-text" : ""}
                        onClick={() => setAba("dados")}
                      >
                        Dados pessoais
                      </h6>
                    </Col>
                    <Col xs={6} md={4} lg={4} onClick={() => setAba("senha")}>
                      <h6
                        className={aba == "senha" ? "active-text" : ""}
                        onClick={() => setAba("senha")}
                      >
                        Alterar senha
                      </h6>
                    </Col>
                  </Row>

                  <Row className="tab-bar">
                    <Col
                      xs={6}
                      md={4}
                      lg={4}
                      onClick={() => setAba("dados")}
                      className={aba == "dados" ? "active-tab" : ""}
                    />
                    <Col
                      xs={6}
                      md={4}
                      lg={4}
                      onClick={() => setAba("senha")}
                      className={aba == "senha" ? "active-tab" : ""}
                    />
                  </Row>
                </div>

                <Form>
                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Senha atual
                        </Form.Label>
                        <div className="fake-input">
                          <Form.Control
                            placeholder="Digite sua senha atual"
                            className="borderless-input"
                            type={showPwd ? "text" : "password"}
                            onChange={(v) => setPassword(v.target.value)}
                          />
                          <div onClick={() => setShowPwd(!showPwd)}>
                            <Icon
                              color={Colors.darker_gray}
                              size={24}
                              icon={showPwd ? "eye-off" : "eye-on"}
                              className="mr-3"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={12} md={12} className="px-0">
                      <Form.Group>
                        <Form.Label className="field-label">
                          Nova senha
                        </Form.Label>
                        <div className="fake-input">
                          <Form.Control
                            placeholder="Digite sua senha"
                            className="borderless-input"
                            type={showNewPwd ? "text" : "password"}
                            onChange={(v) => setNewPwd(v.target.value)}
                          />
                          <div onClick={() => setShowNewPwd(!showNewPwd)}>
                            <Icon
                              color={Colors.darker_gray}
                              size={24}
                              icon={showNewPwd ? "eye-off" : "eye-on"}
                              className="mr-3"
                            />
                          </div>
                        </div>
                        <PwStrengthMeter value={newPwd} handler={setLevelPwd} />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Form>
              </>
            )}
            {tab == 1 && <Usuarios />}
            {tab == 2 && <Unidades />}
            {tab == 3 && <TempoSonoro />}
            {tab == 4 && <TempoSuspensao />}
            {tab == 5 && <PainelSensores />}
            {tab == 6 && <ProtocoloDecubito />}
            {tab == 7 && <ConfirmacaoLesao />}
            {tab == 8 && <EscalaPlantoes />}
            {/* {tab == 1 && (
              <>
                <p className="text-left title-tab">Usuários</p>
              </>
            )}
            {tab == 2 && (
              <>
                <p className="text-left title-tab">Enfermagem</p>
              </>
            )}
            {tab == 3 && (
              <>
                <p className="text-left title-tab">Unidades</p>
              </>
            )}
            {tab == 4 && (
              <>
                <p className="text-left title-tab">Motivos</p>
              </>
            )}
            {tab == 5 && (
              <>
                <p className="text-left title-tab">Tempo sonoro dos avisos</p>
              </>
            )} */}
            {tab == 9 && <AutomacaoDeEmails />}
          </Col>
        </Row>

        <Row style={{ height: 1, backgroundColor: Colors.lighter_gray }}></Row>

        {tab == 0 && aba == "dados" && (
          <Row className="mb-2 mt-3 justify-content-end">
            <div className="botaoSalvar" onClick={() => SalvarAlteracoes()}>
              <div className={loadingBtn != "" ? "mr-2" : ""}>Salvar</div>
              {loadingBtn}
            </div>
          </Row>
        )}
        {tab == 0 && aba == "senha" && (
          <Row className="mb-2 mt-3 justify-content-end">
            <button
              className={`botaoSalvar ${levelPwd < 3 ? "disabled-btn" : ""}`}
              disabled={levelPwd < 3}
              onClick={() => SalvarSenha()}
            >
              <p
                style={{
                  color: levelPwd >= 3 ? Colors.white : Colors.dark_gray,
                }}
                className={loadingBtn != "" ? "mr-2" : ""}
              >
                Salvar
              </p>
              {loadingBtn}
            </button>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default MinhaConta;
