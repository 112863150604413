import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import {
  GetColorAvaliation,
  HexToRgbA,
  userWithoutPermission,
} from "../../../../../functions/utils";
import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import DetailsAvaliation from "./DetailsAvaliation";
import NewAvaliation from "./NewAvaliation";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

var avaliationObj = {};

function AvaliacaoRiscoPaciente() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [listAvaliations, setListAvaliations] = useState([]);
  const [lastAvaliation, setLastAvaliation] = useState({});
  const [showNewAvaliation, setShowNewAvaliation] = useState(false);
  const [showAvaliation, setShowAvaliation] = useState(false);

  useEffect(() => {
    function LoadAvaliations() {
      api
        .get(`patients/${patientSelected.dados.id}/avaliations`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadAvaliations success");
            console.log(response.data);

            if (response.data.length > 0) setLastAvaliation(response.data[0]);
            setListAvaliations(response.data.slice(1));
          }
        })
        .catch((error) => {
          console.log("LoadAvaliations error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as avaliações de riscos desse paciente.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadAvaliations();
  }, [reload]);

  return (
    <div>
      {showNewAvaliation && (
        <NewAvaliation
          handler_show_modal={setShowNewAvaliation}
          show_modal={showNewAvaliation}
          handler_reload={setReload}
          reload={reload}
        />
      )}

      {showAvaliation && (
        <DetailsAvaliation
          handler_show_modal={setShowAvaliation}
          show_modal={showAvaliation}
          avaliation={avaliationObj}
        />
      )}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="screen-name">Avaliacão de risco</h2>
        <button
          disabled={userWithoutPermission(user)}
          onClick={() => setShowNewAvaliation(true)}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          <p
            className="mb-0"
            style={{
              color:
                userWithoutPermission(user) == 3
                  ? Colors.dark_gray
                  : Colors.white,
            }}
          >
            Nova avaliação
          </p>
        </button>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && lastAvaliation.id == undefined && (
        <div className="container-error-default">
          <p>Não foram cadastradas avaliações de risco para esse paciente.</p>
        </div>
      )}

      {!loading && lastAvaliation.id != undefined && (
        <div className="last-avaliation">
          <h2 className="screen-avaliation mb-3">Última avaliação</h2>

          <Row>
            <Col xs={12} md={6}>
              <div
                className="card-avaliation"
                onClick={() => {
                  avaliationObj = lastAvaliation;
                  setShowAvaliation(true);
                }}
              >
                <div
                  className="tag-score"
                  style={{
                    backgroundColor: HexToRgbA(
                      GetColorAvaliation(lastAvaliation.result)
                    ),
                  }}
                >
                  <p
                    style={{
                      color: GetColorAvaliation(lastAvaliation.result),
                    }}
                  >
                    ESCORE
                  </p>
                  <h4
                    style={{
                      color: GetColorAvaliation(lastAvaliation.result),
                    }}
                  >
                    {lastAvaliation.result}
                  </h4>
                </div>

                <div>
                  <p className="avaliation-title">
                    {lastAvaliation.name_result}
                  </p>

                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="c-profile-dropdown mr-2">
                      {renderPhoto(
                        makeUserDataToPhoto(
                          lastAvaliation.user.name,
                          lastAvaliation.user.image !== null
                            ? lastAvaliation.user.url_image
                            : null
                        )
                      )}
                    </div>

                    <div className="dados-default">
                      <div className="name-default">
                        Por {lastAvaliation.user.name}
                      </div>
                      <div className="date-default">
                        {moment(lastAvaliation.created_at).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {!loading && listAvaliations.length > 0 && (
        <div className="all-avaliation mt-4">
          <h2 className="screen-avaliation mb-3">Avaliações anteriores</h2>

          <Row>
            {listAvaliations.map((item) => {
              return (
                <Col xs={12} md={6} key={item.id} className="mb-4">
                  <div
                    className="card-avaliation"
                    onClick={() => {
                      avaliationObj = item;
                      setShowAvaliation(true);
                    }}
                  >
                    <div
                      className="tag-score"
                      style={{
                        backgroundColor: HexToRgbA(
                          GetColorAvaliation(item.result)
                        ),
                      }}
                    >
                      <p
                        style={{
                          color: GetColorAvaliation(item.result),
                        }}
                      >
                        ESCORE
                      </p>
                      <h4
                        style={{
                          color: GetColorAvaliation(item.result),
                        }}
                      >
                        {item.result}
                      </h4>
                    </div>

                    <div>
                      <p className="avaliation-title">{item.name_result}</p>

                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div className="c-profile-dropdown mr-2">
                          {renderPhoto(
                            makeUserDataToPhoto(
                              item.user.name,
                              item.user.image !== null
                                ? item.user.url_image
                                : null
                            )
                          )}
                        </div>

                        <div className="dados-default">
                          <div className="name-default">
                            Por {item.user.name}
                          </div>
                          <div className="date-default">
                            {moment(item.created_at).format("LLL")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </div>
  );
}

export default AvaliacaoRiscoPaciente;
