import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Colors from "../../../../../../../styles/Colors";
import { resizeChartBasedOnWidth } from "../../../../../../../functions/utils";

function SensorPrescriptionTimeTwoBar(props) {
  const { schedules, historics } = props;

  const [labels, setLabels] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);

  const secondsToFullHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    return `${hours.toString().padStart(2, "0")}:00`;
  };

  useEffect(() => {
    if (schedules && historics) {
      const labels = [
        ...new Set(
          [
            ...schedules,
            ...historics.positionTime.filter((i) => i.position !== "Sem sinal"),
          ].map((item) => item.position)
        ),
      ];

      const arr1Values = new Array(labels.length).fill(0);
      const arr2Values = new Array(labels.length).fill(0);

      // Preenchendo arr1Values
      historics.positionTime.forEach((item) => {
        const index = labels.indexOf(item.position);
        if (index !== -1) {
          arr1Values[index] = item.time;
        }
      });

      // Preenchendo arr2Values
      schedules.forEach((item) => {
        const index = labels.indexOf(item.position);
        if (index !== -1) {
          arr2Values[index] = item.time;
        }
      });

      setLabels(labels);
      setSensorData(arr1Values);
      setScheduleData(arr2Values);
    }
  }, [schedules, historics]);

  return (
    <Bar
      height={resizeChartBasedOnWidth()}
      data={{
        labels: labels,
        datasets: [
          {
            label: "Prescrição",
            data: scheduleData,
            backgroundColor: Colors.brand_blue,
            barPercentage: 0.5,
          },
          {
            label: "Sensor",
            data: sensorData,
            backgroundColor: Colors.brand_pink,
            barPercentage: 0.5,
          },
        ],
      }}
      options={{
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (val) => secondsToFullHours(val),
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return (
                tooltipItem[0].label +
                ` - ${
                  tooltipItem[0].datasetIndex == 0 ? "Prescrição" : "Sensor"
                }`
              );
            },
            label: function (tooltipItem, data) {
              return secondsToFullHours(tooltipItem.value);
            },
          },
        },
        legend: {
          position: "bottom",
          align: "center",
          fullWidth: true,
          labels: {
            fontColor: "#A0A4A9",
            usePointStyle: true,
            fontFamily: "Rubik-Regular",
            padding: 27,
          },
        },
      }}
    />
  );
}

export default SensorPrescriptionTimeTwoBar;
