import Colors from "../../../../styles/Colors";

export function getAngleColor(position, isHorizontal) {
  const baseColors = {
    dorsal: Colors.colorBlind_purple,
    prona: Colors.colorBlind_red,
    "em-pe/sentado": Colors.colorBlind_blue,
    esquerda: Colors.new_orange,
    direita: Colors.colorBlind_green,
    "ponta-cabeca": Colors.colorBlind_darkRed,
  };

  const baseColor = baseColors[position] || Colors.light_gray;
  const isStrongerHorizontal = ["dorsal", "prona", "em-pe/sentado"].includes(
    position
  );

  const darkenColor = (color) => {
    switch (color) {
      case Colors.colorBlind_purple:
        return Colors.colorBlind_darkPurple;
      case Colors.colorBlind_red:
        return Colors.colorBlind_darkRed;
      case Colors.colorBlind_blue:
        return Colors.colorBlind_darkBlue;
      case Colors.new_orange:
        return Colors.dark_orange;
      case Colors.colorBlind_green:
        return Colors.colorBlind_darkGreen;
      default:
        return color;
    }
  };

  const lightenColor = (color) => {
    return color + "80";
  };

  if (isStrongerHorizontal === isHorizontal) {
    return darkenColor(baseColor);
  } else {
    return lightenColor(baseColor);
  }
}

export function getColor(name, dark = false) {
  if (dark) {
    if (name === "Dorsal") return Colors.colorBlind_darkPurple;
    if (name === "Direita") return Colors.colorBlind_darkGreen;
    if (name === "Esquerda") return Colors.dark_orange;
    if (name === "Prona") return Colors.colorBlind_darkRed;
    if (name === "Sentado/Em pé") return Colors.colorBlind_darkBlue;
    if (name === "Sem sinal") return Colors.light_gray;
  } else {
    if (name === "Dorsal") return Colors.colorBlind_purple;
    if (name === "Direita") return Colors.colorBlind_green;
    if (name === "Esquerda") return Colors.new_orange;
    if (name === "Prona") return Colors.colorBlind_red;
    if (name === "Sentado/Em pé") return Colors.colorBlind_blue;
    if (name === "Pendente") return Colors.lightest_gray;
    if (name === "Desvinculado") return Colors.lighter_gray;
    if (name === "Sem sinal") return Colors.light_gray;
    return "rgba(0, 0, 0, .1)";
  }
}

export default {
  getColor,
};
