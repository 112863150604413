import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import api from "../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../store/actions/alert";
import "./styles.css";
import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";

import Realizadas from "./TabsDecubito/DecubitoRealizadas/Realizadas";
import NaoRealizadas from "./TabsDecubito/DecubitoNaoRealizadas/NaoRealizadas";
import Esquecidas from "./TabsDecubito/DecubitoEsquecidas/Esquecidas";
import DuplaChecagem from "./TabsDecubito/DecubitosDuplaChecagem/DuplaChecagem";
import Sensor from "./TabsDecubito/DecubitoSensor/Sensor";
import Suspensas from "./TabsDecubito/DecubitoSuspensas/Suspensas";
import Detalhado from "./TabsDecubito/DecubitoDetalhado/Detalhado";
import VisaoGeral from "./VisaoGeral/VisaoGeral";
import PerfilPaciente from "../../../PerfilPaciente/PerfilPaciente";
import RelatoriosPaciente from "../RelatoriosPaciente";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

var btns = [
  {
    name: "Visão Geral",
  },
  {
    name: "Sensor",
  },
  {
    name: "Detalhado",
  },
  // {
  //   name: "Suspensas",
  // },
  // {
  //   name: "Esquecidas",
  // },
];

function RelatorioDecubito() {
  const dispatch = useDispatch();
  const location = useLocation();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [tab, setTab] = useState(1);

  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const handleMiddleClick = (e, subTabIndex) => {
    if (e.button === 1) {
      e.preventDefault();
      const currentTab = getQueryParam("tab");
      const url = `${window.location.origin}${window.location.pathname}?tab=${currentTab}&subtab=${subTabIndex}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    const subTab = getQueryParam("subtab");
    if (subTab !== null && !isNaN(subTab)) {
      setTab(parseInt(subTab));
    }
  }, [location]);

  return (
    <div>
      <div className="container-report mb-4">
        {btns.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setTab(index + 1)}
              onMouseDown={(e) => handleMiddleClick(e, index + 1)}
              className={`report-tab ${
                index + 1 === tab ? "report-active" : ""
              }`}
            >
              <p>{item.name}</p>
            </div>
          );
        })}

        <div className="border-report-all" />
      </div>

      {tab == 1 && <VisaoGeral />}
      {tab == 2 && <Sensor />}
      {tab == 3 && <Detalhado />}
      {/* {tab == 3 && <NaoRealizadas />} */}
      {/* {tab == 3 && <Suspensas />}
      {tab == 4 && <Esquecidas />} */}
      {/* {tab == 5 && <DuplaChecagem />} */}
    </div>
  );
}

export default RelatorioDecubito;
