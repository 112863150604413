import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Table,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import "./styles.css";
import ModalUserFilter from "./ModalUserFilter";
import ModalAddUser from "./ModalAddUser";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalEditUser from "./ModalEditUser";

var userObj = null;
var timer = null;

function Usuarios() {
  const unit = useSelector((state) => state.unit);
  const userLogado = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [word, setWord] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [reload, setReload] = useState(false);

  const [listUsers, setListUsers] = useState([]);

  const [permission, setPermission] = useState([]);
  const [status, setStatus] = useState(true);
  const [units, setUnits] = useState([]);

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);
  const [pagination, setPagination] = useState([]);

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  useEffect(() => {
    function LoadUsers() {
      var permission_id = "";
      if (permission.length > 0) {
        var array = [];
        permission.map((item) => {
          array.push(item.id);
        });
        permission_id = array.join(",");
      }

      var unit_id = unit.selecionado.id;
      if (units.length > 0) {
        var array = [];
        units.map((item) => {
          array.push(item.value);
        });
        unit_id = array.join(",");
      }

      api
        .get(`/users?page=${currentPage}&pageSize=${qtdDisplay}`, {
          params: {
            permission_id,
            status: status == true ? 1 : 0,
            unit_id,
            name: word,
            pagination: true,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers success");
            console.log(response.data);

            setListUsers(response.data.data);
            setLastPage(response.data.lastPage);
            setTotalResults(response.data.total);
            BuildPagination(response.data.page, response.data.lastPage);
          }
        })
        .catch((error) => {
          console.log("LoadUsers error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de usuários",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      LoadUsers();
    }, 500);
  }, [unit, reload, permission, status, units, word, currentPage, qtdDisplay]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  function GetQtdFilters() {
    var qtd = 0;
    if (permission.length > 0) qtd++;
    if (units.length > 0) qtd++;
    return qtd;
  }

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  function RenderUnits(units) {
    if (units.length == 1) {
      return units[0].name;
    } else {
      var array = [];
      units.map((item) => array.push(item.name));
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 10 }}
          overlay={RenderTooltip(array.join(","))}
        >
          <p className="units-text">{units.length} unidades</p>
        </OverlayTrigger>
      );
    }
  }

  return (
    <div>
      {showAddUser && (
        <ModalAddUser
          handler_show_modal={setShowAddUser}
          show_modal={showAddUser}
          handler_reload={setReload}
          reload={reload}
        />
      )}
      {showFilter && (
        <ModalUserFilter
          handler_show_modal={setShowFilter}
          show_modal={showFilter}
          handler_reload={setReload}
          reload={reload}
          handler_permission={setPermission}
          permission={permission}
          handler_status={setStatus}
          status={status}
          handler_units={setUnits}
          units={units}
        />
      )}
      {showDelete && (
        <ModalDeleteUser
          handler_show_modal={setShowDelete}
          show_modal={showDelete}
          handler_reload={setReload}
          reload={reload}
          user={userObj}
        />
      )}
      {showEdit && (
        <ModalEditUser
          handler_show_modal={setShowEdit}
          show_modal={showEdit}
          handler_reload={setReload}
          reload={reload}
          user={userObj}
        />
      )}
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name mb-4">Usuários</h2>
        <button
          onClick={() => setShowAddUser(true)}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          Novo usuário
        </button>
      </div>

      <Row className="align-items-end">
        <Col xs={12} md={8} lg={6} xl={10}>
          <div>
            <Form.Label className="field-label">Buscar usuário</Form.Label>
            <input
              className="default-input"
              type="text"
              placeholder="Digite o nome do usuário"
              onChange={(e) => {
                setCurrentPage(1);
                setWord(e.target.value);
              }}
            />
          </div>
        </Col>

        <Col xs={12} md={4} lg={6} xl={2}>
          <div
            style={{ height: 48 }}
            className={`btn-filter ${GetQtdFilters() > 0 && "active"}`}
            onClick={() => setShowFilter(true)}
          >
            <Icon
              color={
                GetQtdFilters() == 0 ? Colors.darker_gray : Colors.brand_blue
              }
              size={22}
              icon="filter"
              className="mr-2"
            />
            Filtros
            {GetQtdFilters() > 0 && (
              <div className="number-filter">
                <p>{GetQtdFilters()}</p>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {!loading && listUsers.length == 0 && (
        <div className="container-error-default my-3">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listUsers.length > 0 && (
        <div className="mt-3">
          <Table className="user-table" responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Unidade</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listUsers.map((item, index) => {
                return (
                  <tr key={index} onClick={() => {}}>
                    <td>
                      {item.name}
                      <p className="sm-txt" style={{ color: Colors.dark_gray }}>
                        {item.permission.name}
                      </p>
                    </td>
                    <td>{item.email}</td>
                    <td>{RenderUnits(item.units)}</td>
                    <td>
                      <div>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 10 }}
                          overlay={RenderTooltip("Editar")}
                        >
                          <button
                            className="btn-table-user mr-2"
                            onClick={() => {
                              userObj = item;
                              setShowEdit(true);
                            }}
                          >
                            <Icon
                              color={Colors.darker_gray}
                              size={22}
                              icon="edit"
                            />
                          </button>
                        </OverlayTrigger>
                        {userLogado.dados.id !== item.id && (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 10 }}
                            overlay={RenderTooltip("Excluir")}
                          >
                            <button
                              className="btn-table-user"
                              onClick={() => {
                                userObj = item;
                                setShowDelete(true);
                              }}
                            >
                              <Icon
                                color={Colors.darker_gray}
                                size={22}
                                icon="trash"
                              />
                            </button>
                          </OverlayTrigger>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <Row
        className="px-2 w-100"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {renderDisplay()} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {pagination.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    item == currentPage
                      ? "btn-nav-numbers active"
                      : "btn-nav-numbers"
                  }
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => SetarDisplay(12)}>
                12 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(24)}>
                24 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(36)}>
                36 resultados
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default Usuarios;
