import Colors from "../../../../styles/Colors";

export function getColor(name, dark = false) {
  if (dark) {
    if (name === "Dorsal") return Colors.colorBlind_darkPurple;
    if (name === "Direita") return Colors.colorBlind_darkGreen;
    if (name === "Esquerda") return Colors.dark_orange;
    if (name === "Prona") return Colors.colorBlind_darkRed;
    if (name === "Sentado/Em pé") return Colors.colorBlind_darkBlue;
    if (name === "Sem sinal") return Colors.light_gray;
  } else {
    if (name === "Dorsal") return Colors.colorBlind_purple;
    if (name === "Direita") return Colors.colorBlind_green;
    if (name === "Esquerda") return Colors.new_orange;
    if (name === "Prona") return Colors.colorBlind_red;
    if (name === "Sentado/Em pé") return Colors.colorBlind_blue;
    if (name === "Pendente") return Colors.lightest_gray;
    if (name === "Desvinculado") return Colors.lighter_gray;
    if (name === "Sem sinal") return Colors.light_gray;
    return "rgba(0, 0, 0, .1)";
  }
}

export default {
  getColor,
};
